import 'custom-event-polyfill';
import 'navigator.sendbeacon';
import { isServerSide } from '../utils';
import configProvider from './configProvider';

if (!isServerSide()) {
  // IE11 does not support the new Event constructor so we patch
  // over it with the polyfill
  (window.Event as unknown) = window.CustomEvent as unknown;
}

const initFathom = (): void => {
  /*
   * <!-- Fathom - beautiful, simple website analytics -->
   *   <script src="https://cdn.usefathom.com/script.js" site="ID" defer></script>
   * <!-- / Fathom -->
   */
  const fathomSiteId = configProvider.config.fathomSiteId;

  if (fathomSiteId === '' || fathomSiteId === undefined) return;
  const url = 'https://cdn.usefathom.com/script.js';
  const d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.defer = true;
  g.setAttribute('site', fathomSiteId);
  g.setAttribute('spa', 'auto');
  g.src = url;
  if (s && s.parentNode) {
    s.parentNode.insertBefore(g, s);
  } else {
    throw new Error('Fathom script tag could not be inserted to DOM');
  }
};

export { initFathom };
