import React, { useContext } from 'react';
import { EntryFields, Entry } from 'contentful';
import styled, { ThemeContext } from 'styled-components';
import { KText } from '@xenzonegroup/kompass';
import { getDefaultTextColourForTextFriendlyBackgroundColour } from '@xenzonegroup/kompass/lib_out/src/styles/themes';
import { TextFriendlyBackgroundColourName } from '@xenzonegroup/kompass/lib_out/src/types/StyledComponentsDefaultTheme';
import { VideoLink, VideoLinkType } from './VideoLink';

import styles from './stats-module.scss';

interface StatisticType {
  title: EntryFields.Text;
  value: EntryFields.Text;
}

interface StatsModuleType {
  backgroundColour: TextFriendlyBackgroundColourName;
  blurb: EntryFields.Text;
  statistics: Entry<StatisticType>[];
  videoLink?: Entry<VideoLinkType>;
}

const UnstyledStatsModule: React.FC<{
  content: Entry<StatsModuleType>;
  className?: string;
}> = ({ content, className }) => {
  const theme = useContext(ThemeContext);
  return (
    <section className={`${className} ${styles.statsModule}`}>
      <div>
        <dl className={styles.stats}>
          {content.fields.statistics.map((statistic, i) => (
            <div key={i}>
              <dt>
                <KText
                  textStyle="heading_S"
                  colour={getDefaultTextColourForTextFriendlyBackgroundColour(
                    content.fields.backgroundColour,
                    theme
                  )}
                >
                  {statistic.fields.title}
                </KText>
              </dt>
              <dd>
                <KText
                  colour={getDefaultTextColourForTextFriendlyBackgroundColour(
                    content.fields.backgroundColour,
                    theme
                  )}
                  textStyle="body_large"
                >
                  {statistic.fields.value}
                </KText>
              </dd>
            </div>
          ))}
        </dl>
        <div className={styles.blurb}>
          {content.fields.blurb.split('\n').map((line, i) => {
            if (line.trim() != '') {
              return (
                <KText
                  colour={getDefaultTextColourForTextFriendlyBackgroundColour(
                    content.fields.backgroundColour,
                    theme
                  )}
                  key={i}
                  textStyle="body_large"
                >
                  {line}
                </KText>
              );
            }
          })}
          {!!content.fields.videoLink && (
            <VideoLink
              content={content.fields.videoLink}
              backgroundColour={content.fields.backgroundColour}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export const StatsModule = styled(UnstyledStatsModule)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours[
      props.content.fields.backgroundColour
    ]};
`;
