import { PageContainingNavigationBarAndFooter } from '../components/PageWrapper';
import { Entry } from 'contentful';

require('isomorphic-fetch');

interface ApiError {
  error: string;
}

class ResponseError extends Error {
  constructor(
    public readonly error: string,
    public readonly response: Response,
    public readonly apiError: ApiError
  ) {
    super(error);
  }
}

function isResponseError(error: any): error is ResponseError {
  return (
    error.error != undefined &&
    error.response != undefined &&
    error.apiError != undefined
  );
}

const handleApiErrors = async (response: Response): Promise<Response> => {
  if (!response.ok) {
    const error: ApiError = await response.json();
    throw new ResponseError(error.error, response, error);
  }
  return response;
};

const fetchContent = <T = Entry<PageContainingNavigationBarAndFooter>>(
  url: string
): Promise<T> =>
  fetch(url, {
    headers: { Accept: 'application/json' },
  })
    .then(handleApiErrors)
    .then(res => res.json());

export { fetchContent, ResponseError, isResponseError };
