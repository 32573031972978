import React from 'react';
import { Entry } from 'contentful';
import { KTextlessLink } from '@xenzonegroup/kompass';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationBarType } from './NavigationMenu/NavigationBar';
import { NavigationMenu } from './NavigationMenu';
import { NavigationProvider } from './NavigationMenu/NavigationProvider';
import IconLogo from '../images/icon-logo.svg';
import styles from './header.scss';

interface HeaderProps {
  content?: Entry<NavigationBarType>;
  className?: string;
}

const UnstyledHeader: React.FC<HeaderProps> = ({ content, className }) => {
  return (
    <NavigationProvider>
      <div className={styles.headerContentWrapper}>
        <header className={`${styles.headerBar} ${className}`}>
          <nav>
            <KTextlessLink renderReactRouterLinkInstead={Link} to="/">
              <img src={IconLogo} alt="Home" />
            </KTextlessLink>

            {content && <NavigationMenu content={content} />}
          </nav>
        </header>
      </div>
    </NavigationProvider>
  );
};

const Header = styled(UnstyledHeader)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours
      .elevated_background_level2};
  border: ${props => props.theme.colours.decorative_colours.border_faint} solid
    1px;
`;

export default Header;
