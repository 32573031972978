import fs from 'fs';
import path from 'path';
import { isServerSide } from '../utils';

const LOCAL_CONFIG: Config = {
  environment: 'development',
  apiHostnames: {
    content: 'https://content-proxy.apis.unstable.test.stacks.xenzonegroup.com',
  },
  pages: {
    '/': '5XhfEvdEMeUW4vm8jjHCGW',
    '/our-products/young-people': '7vrBXsUoJKow5TZoYZQrKS',
    '/our-products/adults': '7fEHIvgEI3BjA2TkdlGKjd',
    '/our-products/soluna': '7HqklEmrXaruc97Z4yzmRJ',
    '/our-products/students': 'zNUbSovqmJRSl1fTMMD9r',
    '/404': '49ooukwk9Sw4FWMdZlitfE',
    '/contact-us': '5Bs1xVPJ3smKN5vxOrpxKc',
    '/privacy': '1qLXgnbsS4owdyfvaMThl1',
    '/our-purpose': '2Qqza26wWScNhmTHOku3Ns',
    '/about-us/join-us': '5Ilkgtznv4wEJtMlxJchhu',
    '/about-us/who-we-are': '3oSfEFkklAowduCLP6dV49',
    '/about-us/our-story': '3HSG4cmwbKyyLBShPhVPuO',
    '/about-us/research': '4UW4H4wDA8vPV0vofpY8sh',
    '/about-us/transparency-center': '5KskxV5taOSGq34OfJfwQ',
    '/about-us/gender-pay-gap': '7FVlr5jaHXYnzk9Yl90tMv',
  },
  featureFlags: {
    scrollToTop: true,
  },
  sentryDsn:
    'https://486142e33ee34e959f50599986138654@o367623.ingest.sentry.io/5530975',
};

let config;
if (isServerSide()) {
  if (process.env.NODE_ENV === 'development') {
    config = LOCAL_CONFIG;
  } else {
    config = JSON.parse(
      fs
        .readFileSync(
          path.join(process.env.NODE_CONFIG_DIR || '', 'config.json')
        )
        .toString()
    );
  }
} else {
  config = window.config;
}

export default {
  config: config,
};
