import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { Kompass } from '@xenzonegroup/kompass';
import PageWrapper, {
  PageContainingNavigationBarAndFooter,
} from './components/PageWrapper';
import { setFavicon } from './utils';
import configProvider from './utils/configProvider';
import styles from './styles/stylesheet.scss';
import { Entry } from 'contentful';
import * as Sentry from '@sentry/react';
import { ErrorLoadingPage } from './pages/ErrorLoadingPage';

const SlugRouter: React.FC<RouteComponentProps<{
  slug: string;
}> & {
  serverContent?: Entry<PageContainingNavigationBarAndFooter>;
  contentType: 'article' | 'privacyPage';
}> = ({ match, serverContent, contentType }) => (
  <PageWrapper
    slug={match.params.slug}
    contentType={contentType}
    serverContent={serverContent}
  />
);

const App: React.FC<{
  serverContent?: Entry<PageContainingNavigationBarAndFooter>;
}> = ({ serverContent }) => {
  const { pages } = configProvider.config;

  setFavicon();

  if (configProvider.config.sentryDsn) {
    Sentry.init({
      dsn: configProvider.config.sentryDsn,
      environment: configProvider.config.environment,
    });
  }

  return (
    <Kompass mode="corporate">
      <div className={styles.corporate}>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => (
            <ErrorLoadingPage resetError={resetError} />
          )}
        >
          <Switch>
            <Route path="/our-products">
              <Route path="/our-products/young-people">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/our-products/young-people']}
                />
              </Route>

              <Route path="/our-products/adults">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/our-products/adults']}
                />
              </Route>

              <Route path="/our-products/soluna">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/our-products/soluna']}
                />
              </Route>

              <Route path="/our-products/students">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/our-products/students']}
                />
              </Route>
            </Route>

            <Route path="/about-us">
              <Route path="/about-us/join-us">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/about-us/join-us']}
                />
              </Route>

              <Route path="/about-us/who-we-are">
                <Route path="/about-us/who-we-are" exact>
                  <PageWrapper
                    serverContent={serverContent}
                    contentId={pages['/about-us/who-we-are']}
                  />
                </Route>

                <Route
                  path="/about-us/who-we-are/:slug"
                  component={(props: RouteComponentProps<{ slug: string }>) => (
                    <SlugRouter
                      {...{ ...props, serverContent, contentType: 'article' }}
                    />
                  )}
                />
              </Route>

              <Route path="/about-us/our-story">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/about-us/our-story']}
                />
              </Route>

              <Route path="/about-us/research">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/about-us/research']}
                />
              </Route>

              <Route path="/about-us/transparency-center">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/about-us/transparency-center']}
                />
              </Route>

              <Route path="/about-us/gender-pay-gap">
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/about-us/gender-pay-gap']}
                />
              </Route>
            </Route>

            <Route path="/our-purpose">
              <Route path="/our-purpose" exact>
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/our-purpose']}
                />
              </Route>

              <Route
                path="/our-purpose/:slug"
                component={(props: RouteComponentProps<{ slug: string }>) => (
                  <SlugRouter
                    {...{ ...props, serverContent, contentType: 'article' }}
                  />
                )}
              />
            </Route>

            <Route path="/contact-us">
              <PageWrapper
                serverContent={serverContent}
                contentId={pages['/contact-us']}
              />
            </Route>

            <Route path="/privacy">
              <Route path="/privacy" exact>
                <PageWrapper
                  serverContent={serverContent}
                  contentId={pages['/privacy']}
                />
              </Route>

              <Route
                path="/privacy/:slug"
                component={(
                  props: RouteComponentProps<{
                    slug: string;
                  }>
                ) => (
                  <SlugRouter
                    {...{ ...props, serverContent, contentType: 'privacyPage' }}
                  />
                )}
              ></Route>
            </Route>

            <Route exact path="/">
              <PageWrapper
                serverContent={serverContent}
                contentId={pages['/']}
              />
            </Route>

            <Route
              path="/article/:slug"
              component={(props: RouteComponentProps<{ slug: string }>) => (
                <SlugRouter
                  {...{ ...props, serverContent, contentType: 'article' }}
                />
              )}
            />

            <Route path="*">
              <PageWrapper
                serverContent={serverContent}
                contentId={pages['/404']}
              />
            </Route>
          </Switch>
        </Sentry.ErrorBoundary>
      </div>
    </Kompass>
  );
};

export { App };
