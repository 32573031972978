import { breadthFirstSearchResolveContentLinks } from './breadthFirstSearchResolveContentLinks';

const resolveContentLinks = (
  url: string,
  data: Record<string, any>
): Record<string, any> => {
  const dataHasLinksToResolve = data.items && data.items[0];

  if (!dataHasLinksToResolve) {
    return data;
  }

  const params = new URL(url).searchParams.get('include');

  const contentModel = data.items[0];
  const resolveContentModelLinksWith = data.includes;
  const resolveDepth = Number(params);

  return breadthFirstSearchResolveContentLinks(
    contentModel,
    resolveContentModelLinksWith,
    resolveDepth
  );
};

export { resolveContentLinks };
