import React, { useEffect } from 'react';
import { KText, KLink } from '@xenzonegroup/kompass';
import styled from 'styled-components';
import { EntryFields, Entry } from 'contentful';
import { DescriptionListItemType } from './DescriptionList';
import { isServerSide } from '../utils';

import styles from './timeline.scss';

export interface TimelineType {
  name: EntryFields.Text;
  strapline: EntryFields.Text;
  milestones: Entry<DescriptionListItemType>[];
}

let timeout: number;
const debounce = (func: () => any): any => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(func, 100);
};

const SingleMilestone: React.FC<{
  milestone: any;
  isSubMilestone?: boolean;
}> = ({ milestone, isSubMilestone }) => (
  <React.Fragment>
    <dt>
      {milestone.fields.url ? (
        <KLink
          href={milestone.fields.url}
          openInNewTab
          colour="primary_inverted"
          textStyle={isSubMilestone ? 'heading_S' : 'heading_M'}
        >
          {milestone.fields.title}
        </KLink>
      ) : (
        <KText
          colour="primary_inverted"
          textStyle={isSubMilestone ? 'heading_S' : 'heading_M'}
          inline
        >
          {milestone.fields.title}
        </KText>
      )}
    </dt>
    <dd>
      <KText colour="primary_inverted" inline>
        {milestone.fields.description}
      </KText>
    </dd>
  </React.Fragment>
);

const Milestone: React.FC<{ milestone: any }> = ({ milestone }) => {
  const hasSubFields =
    milestone.fields &&
    milestone.fields.subList &&
    milestone.fields.subList.length >= 1;

  if (hasSubFields) {
    return (
      <React.Fragment>
        <dt>
          <KText colour="primary_inverted" textStyle="heading_M" inline>
            {milestone.fields.title}
          </KText>
        </dt>
        <dd>
          <dl>
            {milestone.fields.subList.map((subMilestone: any) => (
              <SingleMilestone
                key={subMilestone.sys.id}
                milestone={subMilestone}
                isSubMilestone
              />
            ))}
          </dl>
        </dd>
      </React.Fragment>
    );
  } else {
    return <SingleMilestone milestone={milestone} />;
  }
};

const UnstyledTimeline: React.FC<{
  content: Entry<TimelineType>;
  className?: string;
  positionOnPage: number;
}> = ({ content, className, positionOnPage }) => {
  const [lastMileStoneHeight, setLastMileStoneHeight] = React.useState<
    number
  >();

  const isFirst = positionOnPage === 0;

  const getLastMileStoneHeight = (): void => {
    if (isServerSide()) {
      return;
    }
    const allDdElements = document.getElementsByTagName('dd');
    if (!allDdElements.length) {
      return;
    }
    const lastDdElement = allDdElements[allDdElements.length - 1];
    const lastDtElement =
      allDdElements[allDdElements.length - 1].previousElementSibling;
    if (!lastDtElement || !lastDtElement) {
      return;
    }
    const lastDdElementStyles = window.getComputedStyle(lastDdElement);
    setLastMileStoneHeight(
      lastDdElement.clientHeight +
        parseInt(lastDdElementStyles.marginBottom) +
        lastDtElement?.clientHeight
    );
  };

  const debouncedTimeout = (): void => debounce(getLastMileStoneHeight);

  useEffect(() => {
    if (!isServerSide()) {
      window.addEventListener('resize', debouncedTimeout);
      getLastMileStoneHeight();
      return () => {
        window.removeEventListener('resize', debouncedTimeout);
      };
    }
  }, []);

  return (
    <section
      className={`${className} ${styles.timeline} ${
        isFirst ? styles.timelineFirstOnPage : ''
      }`}
    >
      <style data-testid="timeline-inline-style">
        {`.${styles.timeline} > div > dl::before { height: calc(100% - 0.625rem - ${lastMileStoneHeight}px); }`}
      </style>
      <div>
        <KText colour="primary_inverted" textStyle="large_text">
          {content.fields.strapline}
        </KText>

        <dl>
          <div className={styles.line} />
          {content.fields.milestones.map(milestone => (
            <Milestone key={milestone.sys.id} milestone={milestone} />
          ))}
        </dl>
      </div>
    </section>
  );
};

export const Timeline = styled(UnstyledTimeline)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours.coloured_background_2};
`;
