import React from 'react';
import { Entry } from 'contentful';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NavigationBarType } from './NavigationBar';
import { currentUrlContainedInNavBar } from './currentUrlContainedInNavBar';
import { mapContentTypeToComponent } from '../component-mapper';
import styles from './navigation-list.scss';
import { navigationStore } from './NavigationProvider';
import { DropdownProvider } from './DropdownProvider';

interface NavigationListProps {
  content: Entry<NavigationBarType>;
  className?: string;
}

export const UnstyledNavigationList: React.FC<NavigationListProps> = ({
  content,
  className,
}) => {
  const location = useLocation();

  const { modalIsOpen } = React.useContext(navigationStore);

  const currentClasses = `current-parent ${styles.currentParent}`;

  const containerRef = React.useRef<HTMLUListElement>(null);

  return (
    <DropdownProvider containerRef={containerRef}>
      <ul
        className={`${styles.navigationList} ${className} ${
          modalIsOpen ? styles.modalIsOpen : styles.modalIsClosed
        }`}
        data-testid="navigation-list"
        ref={containerRef}
      >
        {content.fields.links.map(link => {
          const Component = mapContentTypeToComponent(
            link.sys.contentType.sys.id
          );
          const isCurrent = currentUrlContainedInNavBar(link, location);

          return (
            <li
              key={link.sys.id}
              className={`${isCurrent ? currentClasses : ''}  ${
                styles.topLevelLi
              }`}
              data-testid={`item-${link.sys.id}`}
            >
              <Component content={link} />
            </li>
          );
        })}
      </ul>
    </DropdownProvider>
  );
};

export const NavigationList = styled(UnstyledNavigationList)`
  li.current-parent {
    border-color: ${props => props.theme.colours.text_colours.interaction};
  }
`;
