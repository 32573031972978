import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading, KText, KLink, KIconLink } from '@xenzonegroup/kompass';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import styles from './card-articles.scss';
import styled from 'styled-components';
import { ArticleType } from '../pages/Article';

const DATE_FORMAT = 'd LLLL, yyyy';

export interface CardArticlesType {
  title: EntryFields.Text;
  articles?: Entry<ArticleType>[];
  cardType?: 'news' | 'case studies';
}

const CaseStudiesCardArticles: React.FC<{
  content: Entry<CardArticlesType>;
}> = ({ content }) => (
  <div className={styles.caseStudiesCardArticles}>
    {content.fields.articles?.map(article => (
      <article className={styles.cardArticle} key={article.sys.id}>
        <div className={styles.cardArticleThumbnailAndHeader}>
          {article.fields.thumbnailImage && (
            <img
              className={styles.articleThumbnail}
              src={article.fields.thumbnailImage.fields.file.url}
              alt={article.fields.thumbnailImage.fields.description || ''}
            />
          )}
          <KLink
            renderReactRouterLinkInstead={Link}
            to={{
              pathname: `/article/${article.fields.slug}`,
              state: { shouldMoveFocusToPageH1: true },
            }}
            textStyle="heading_S"
            className={styles.articleLinkTitle}
          >
            {article.fields.title}
          </KLink>
        </div>
        {!!article.fields.summary && (
          <KText
            className={styles.articleSummary}
            inline
            textStyle="body_large"
          >
            {article.fields.summary}
          </KText>
        )}
      </article>
    ))}
  </div>
);

const UnstyledNewsCardArticles: React.FC<{
  content: Entry<CardArticlesType>;
  className?: string;
}> = ({ content, className }) => (
  <div className={styles.newsCardArticles}>
    {content.fields.articles?.map(article => (
      <article key={article.sys.id}>
        <div className={`${className} ${styles.content}`}>
          {article.fields.date && (
            <KText textStyle="small_text">
              {format(parseISO(article.fields.date), DATE_FORMAT)}
            </KText>
          )}
          <KHeading
            level={3}
            textStyle="heading_XS"
            id={`card_article_heading_${article.sys.id}`}
            className={styles.articleLinkTitle}
          >
            {article.fields.title}
          </KHeading>
          <KIconLink
            label="Read now"
            icon="forward"
            labelPosition="left"
            renderReactRouterLinkInstead={Link}
            to={`/article/${article.fields.slug}`}
            colour="interaction"
            id={`card_article_link_${article.sys.id}`}
            aria-labelledby={`card_article_link_${article.sys.id} card_article_heading_${article.sys.id}`}
          />
        </div>
        {article.fields.thumbnailImage && (
          <div>
            <img
              className={styles.articleThumbnail}
              src={article.fields.thumbnailImage.fields.file.url}
              alt={article.fields.thumbnailImage.fields.description || ''}
            />
          </div>
        )}
      </article>
    ))}
  </div>
);

export const NewsCardArticles = styled(UnstyledNewsCardArticles)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours
      .elevated_background_level2};
`;

const CaseStudies: React.FC<{
  content: Entry<CardArticlesType>;
  partOfAnotherSection?: boolean;
}> = ({ content, partOfAnotherSection }) => {
  if (partOfAnotherSection) {
    return <CaseStudiesCardArticles content={content} />;
  }

  return (
    <section className={styles.caseStudiesCardArticlesSection}>
      <div>
        <KHeading level={2} textStyle="heading_M">
          {content.fields.title}
        </KHeading>
        <CaseStudiesCardArticles content={content} />
      </div>
    </section>
  );
};

const News: React.FC<{
  content: Entry<CardArticlesType>;
  partOfAnotherSection?: boolean;
}> = ({ content, partOfAnotherSection }) => {
  if (partOfAnotherSection) {
    return <NewsCardArticles content={content} />;
  }

  return (
    <section className={styles.newsCardArticlesSection}>
      <div>
        <KHeading level={2} textStyle="heading_S">
          {content.fields.title}
        </KHeading>
        <NewsCardArticles content={content} />
      </div>
    </section>
  );
};

const CardArticles: React.FC<{
  content: Entry<CardArticlesType>;
  partOfAnotherSection?: boolean;
}> = ({ content, partOfAnotherSection = false }) => {
  if (content.fields.cardType === 'case studies') {
    return (
      <CaseStudies
        content={content}
        partOfAnotherSection={partOfAnotherSection}
      />
    );
  }
  return <News content={content} partOfAnotherSection={partOfAnotherSection} />;
};

export { CardArticles };
