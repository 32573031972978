import React from 'react';
import { Link } from 'react-router-dom';
import { KHeading, KLink, KText, KIconLink } from '@xenzonegroup/kompass';
import { EntryFields, Entry, Asset } from 'contentful';
import { TextFriendlyBackgroundColourName } from '@xenzonegroup/kompass/lib_out/src/types/StyledComponentsDefaultTheme';
import styled from 'styled-components';

import styles from './card-links.scss';
import featuredCaseStudyStyles from './featured-case-study.scss';

import { TextColourName } from '@xenzonegroup/kompass/lib_out/src/types/colours';

export interface CardLinkType {
  title: EntryFields.Text;
  link?: EntryFields.Text;
  image: Asset;
  description?: EntryFields.Text;
  pdf?: Asset;
}

type CardLinkStyle = 'default' | 'dark_hero' | 'tiles';

export interface CardLinksType {
  name: EntryFields.Text;
  title: EntryFields.Text;
  cardLinks: Entry<CardLinkType>[];
  featuredCardLink: Entry<CardLinkType>;
  backgroundColour: TextFriendlyBackgroundColourName;
  style: CardLinkStyle;
}

const FeaturedCardLink: React.FC<{
  content: Entry<CardLinkType>;
  colour: TextColourName;
  title: string;
}> = ({ content, title, colour }) => {
  const showTitle = title || 'Case studies';
  return (
    <div className={featuredCaseStudyStyles.featuredCaseStudy}>
      <div className={featuredCaseStudyStyles.contentWrapper}>
        <KHeading colour={colour} level={2} textStyle="heading_S">
          {showTitle}
        </KHeading>

        <div className={featuredCaseStudyStyles.content}>
          <div className={featuredCaseStudyStyles.text}>
            <KHeading
              colour={colour}
              level={3}
              textStyle="heading_M"
              id="featured_case_study_heading"
            >
              {content.fields.title}
            </KHeading>
            {!!content.fields.description && (
              <KText colour={colour} textStyle="body_large">
                {content.fields.description}
              </KText>
            )}
            {!!content.fields.pdf ? (
              <KIconLink
                icon="forward"
                label="Download paper"
                labelPosition="left"
                colour="interaction"
                href={content.fields.pdf?.fields.file.url}
                id="featured_case_study_link"
                openInNewTab
                type="application/pdf"
                aria-labelledby="featured_case_study_link featured_case_study_heading"
              />
            ) : !!content.fields.link ? (
              <KIconLink
                icon="forward"
                label="Download paper"
                labelPosition="left"
                colour="interaction"
                id="featured_case_study_link"
                href={content.fields.link || ''}
                openInNewTab
                aria-labelledby="featured_case_study_link featured_case_study_heading"
              />
            ) : (
              content.fields.title
            )}
          </div>
        </div>
      </div>

      {content.fields.image && (
        <img
          className={`${featuredCaseStudyStyles.image} ${featuredCaseStudyStyles.imageDesktop}`}
          src={content.fields.image.fields.file.url}
          alt={content.fields.image.fields.description || ''}
        />
      )}
    </div>
  );
};

const UnstyledCardLink: React.FC<{
  cardLink: Entry<CardLinkType>;
  colour: TextColourName;
  className?: string;
  cardLinkStyle: CardLinkStyle;
}> = ({ cardLink, colour, className, cardLinkStyle }) => {
  const headingStyle =
    cardLinkStyle === 'dark_hero' ? 'heading_M' : 'heading_S';
  return (
    <article
      className={`${cardLinkStyle === 'tiles' ? className : ''} ${
        styles.cardLink
      } ${!!cardLink.fields.description && styles.cardLinkWithDescription}`}
    >
      {cardLink.fields.image && (
        <img
          src={cardLink.fields.image.fields.file.url}
          alt={cardLink.fields.image.fields.description || ''}
        />
      )}
      <KHeading level={3} colour={colour} textStyle={headingStyle}>
        {!!cardLink.fields.pdf ? (
          <KLink
            href={cardLink.fields.pdf?.fields.file.url}
            openInNewTab
            textStyle="heading_S"
            type="application/pdf"
            standalone
          >
            {cardLink.fields.title}
          </KLink>
        ) : !!cardLink.fields.link ? (
          <KLink
            href={cardLink.fields.link || ''}
            textStyle="heading_S"
            standalone
          >
            {cardLink.fields.title}
          </KLink>
        ) : (
          cardLink.fields.title
        )}
      </KHeading>
      {!!cardLink.fields.description && (
        <KText inline textStyle="body_large" colour={colour}>
          {cardLink.fields.description}
        </KText>
      )}
    </article>
  );
};

const CardLink = styled(UnstyledCardLink)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours
      .elevated_background_level2};
`;

const UnstyledCardLinks: React.FC<{
  content: Entry<CardLinksType>;
  className?: string;
}> = ({ content, className }) => {
  const textColour =
    content.fields.style === 'dark_hero' ? 'primary_inverted' : 'primary';
  return (
    <>
      <section
        className={`${styles.cardLinks} ${className} ${
          styles[content.fields.style]
        }`}
      >
        <div>
          {content.fields.featuredCardLink && (
            <FeaturedCardLink
              content={content.fields.featuredCardLink}
              title={content.fields.title}
              colour={textColour}
            />
          )}
          {!content.fields.featuredCardLink && (
            <KHeading level={2} textStyle="heading_M" colour={textColour}>
              {content.fields.title}
            </KHeading>
          )}
          <div className={styles.cardLinksList}>
            {content.fields.cardLinks.map(cardLink => (
              <CardLink
                key={cardLink.sys.id}
                cardLink={cardLink}
                colour={textColour}
                cardLinkStyle={content.fields.style}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export const CardLinks = styled(UnstyledCardLinks)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours[
      props.content.fields.backgroundColour
    ]};
`;
