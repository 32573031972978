import {
  ErrorNotFoundPage,
  ContactUs,
  InfoPage,
  LandingPage,
  Article,
  SimplePage,
  PrivacyPage,
} from '../pages';
import { NavigationLink } from './NavigationMenu/NavigationLink';
import { NavigationBar } from './NavigationMenu/NavigationBar';
import { BlurbModule } from './BlurbModule';
import { CareersListingModule } from './CareersListingModule';
import { CardLinks } from './CardLinks';
import { StatsModule } from './StatsModule';
import { DescriptionList } from './DescriptionList';
import { FullWidthImage } from './FullWidthImage';
import { Timeline } from './Timeline';
import { TexturedBillboard } from './TexturedBillboard';
import { CardArticles } from './CardArticles';
import { PeopleModule } from './PeopleModule';
import { PeopleLists } from './PeopleLists';

type ComponentMapper = {
  [contentType: string]: React.ComponentType<any>;
};

const components: ComponentMapper = {
  errorPage: ErrorNotFoundPage,
  contactUs: ContactUs,
  navigationLink: NavigationLink,
  navigationBar: NavigationBar,
  infoPage: InfoPage,
  blurb: BlurbModule,
  careerListings: CareersListingModule,
  cardArticles: CardArticles,
  cardLinks: CardLinks,
  stats: StatsModule,
  descriptionList: DescriptionList,
  fullWidthImage: FullWidthImage,
  landingPage: LandingPage,
  timeline: Timeline,
  texturedBillboard: TexturedBillboard,
  article: Article,
  people: PeopleModule,
  peopleLists: PeopleLists,
  simplePage: SimplePage,
  privacyPage: PrivacyPage,
};

export const mapContentTypeToComponent = (
  contentType: string
): React.ComponentType<any> => {
  if (components[contentType] === undefined) {
    throw `${contentType} does not map to a component`;
  }
  return components[contentType];
};
