import React from 'react';
import { Entry } from 'contentful';
import { KIconButton, KModalDialog } from '@xenzonegroup/kompass';
import { NavigationList } from './NavigationList';
import { NavigationBarType } from './NavigationBar';
import { navigationStore } from './NavigationProvider';
import styles from './index.scss';

const idOfNavMenuButton = 'nav_menu_toggle_button_8e89yhln68oi';

interface NavigationMenuProps {
  content: Entry<NavigationBarType>;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ content }) => {
  const { modalIsOpen, openModal, closeModal } = React.useContext(
    navigationStore
  );

  const menu = <NavigationList content={content} />;

  return (
    <>
      <span className={styles.menuiconContainer}>
        <KIconButton
          colour="interaction"
          icon="burger"
          label="Menu"
          labelPosition="left"
          onClick={openModal}
          data-testid="mobile-navmenu-button"
          id={idOfNavMenuButton}
        />
      </span>
      {modalIsOpen ? (
        <KModalDialog
          dialogLayout="right_side_column"
          onClose={closeModal}
          dialogAccessibilityName="navigation"
          idOfTriggerButton={idOfNavMenuButton}
          closeButtonLabel="Close menu"
        >
          {menu}
        </KModalDialog>
      ) : (
        menu
      )}
    </>
  );
};
