import React from 'react';
import { Entry, EntryFields } from 'contentful';
import { Link } from 'react-router-dom';
import { KHeading, KText, KLink } from '@xenzonegroup/kompass';
import { NavigationLinkType } from '../components/NavigationMenu/NavigationLink';
import styles from './error-not-found-page.scss';

export interface ErrorPageType {
  title: EntryFields.Text;
  strapline: EntryFields.Text;
  linkListTitle: EntryFields.Text;
  linkList: Entry<NavigationLinkType>[];
  metaTitle: EntryFields.Text;
}

interface ErrorPageProps {
  content: Entry<ErrorPageType>;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ content }) => {
  return (
    <div className={styles.inner}>
      <KHeading level={1} textStyle="heading_L">
        {content?.fields.title}
      </KHeading>
      <KText textStyle="large_text">{content?.fields.strapline}</KText>
      <KHeading level={2} textStyle="body">
        {content?.fields.linkListTitle}
      </KHeading>
      <ul role="list">
        {content?.fields.linkList.map(e => (
          <li key={e.sys.id}>
            <KLink
              renderReactRouterLinkInstead={Link}
              to={{
                pathname: e.fields.url,
                state: { shouldMoveFocusToPageH1: true },
              }}
              textStyle="body"
              fontWeight={400}
            >
              {e.fields.linkText}
            </KLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorPage;
