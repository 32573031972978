import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading, KLink, KText } from '@xenzonegroup/kompass';
import { useGetContent } from '../content/useGetContent';
import { LoadingSpinner } from './LoadingSpinner';

import livingWageImage from '../images/lw_logo_employer_rgb-850@2x.png';
import styles from './careers-listing-module.scss';
import configProvider from '../utils/configProvider';

interface WorkableJob {
  id: string;
  title: string;
  shortlink: string;
  code?: string;
  department?: string;
  location: {
    location_str: string;
  };
}

interface ContentfulJob {
  name: EntryFields.Text;
  url: EntryFields.Text;
  code?: EntryFields.Text;
  department?: EntryFields.Text;
  location?: EntryFields.Text;
  createdAt?: EntryFields.Text;
}

interface CareersListingType {
  title: EntryFields.Text;
  jobs?: Entry<ContentfulJob>[];
}

const Job: React.FC<{
  url: string;
  title: string;
  code?: string;
  department?: string;
  location?: string;
  createdAt?: string;
}> = ({ url, title, code, department, location, createdAt }) => (
  <li>
    <KHeading level={3} textStyle="heading_XS">
      <KLink textStyle="heading_XS" href={url}>
        {title}
      </KLink>
    </KHeading>
    {code && <KText>Code: {code}</KText>}
    {department && <KText>Department: {department}</KText>}
    {location && <KText>Location: {location}</KText>}
    {createdAt && <KText>Creation date: {createdAt}</KText>}
  </li>
);

const JobList: React.FC<{
  isError: boolean;
  contentfulJobs?: Entry<ContentfulJob>[];
  workableJobs?: WorkableJob[];
}> = ({ contentfulJobs, isError, workableJobs }) => (
  <ul role="list">
    {isError
      ? contentfulJobs?.map(job => (
          <Job
            key={job.sys.id}
            url={job.fields.url}
            title={job.fields.name}
            code={job.fields.code}
            department={job.fields.department}
            location={job.fields.location}
            createdAt={job.fields.createdAt}
          />
        ))
      : workableJobs &&
        workableJobs?.map(job => (
          <Job
            key={job.id}
            url={job.shortlink}
            title={job.title}
            code={job.code}
            department={job.department}
            location={job.location.location_str}
          />
        ))}
  </ul>
);

const CareersListingModule: React.FC<{
  content: Entry<CareersListingType>;
}> = ({ content }) => {
  const { apiHostnames } = configProvider.config;
  const { data, isError } = useGetContent<WorkableJob[]>(
    `${apiHostnames.content}/jobs`
  );

  return (
    <section>
      <div className={styles.careers}>
        <KHeading level={2} textStyle="heading_S">
          {content.fields.title}
        </KHeading>

        {!data && !isError ? (
          <div className={styles.loading}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <JobList
              isError={isError}
              contentfulJobs={content.fields.jobs}
              workableJobs={data}
            />
            <div className={styles.livingWage}>
              <img src={livingWageImage} alt="We are a living wage employer" />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export { CareersListingModule };
