import { useEffect } from 'react';
import favicon from './images/favicon.svg';

const useSetMetaProperty = (name: string, content?: string): void => {
  useEffect(() => {
    if (isServerSide()) {
      return;
    }
    const metaElements = document.getElementsByName(name);

    if (!metaElements.length) {
      const newMetaElement = document.createElement('meta');
      newMetaElement.setAttribute('name', name);
      newMetaElement.setAttribute('property', `og:${name}`);
      newMetaElement.setAttribute('content', content || '');

      document.head.appendChild(newMetaElement);
    } else {
      metaElements[0].setAttribute('content', content || '');
    }
  }, [content]);
};

const setFavicon = (): void => {
  if (isServerSide()) {
    return;
  }
  const linkEl = document.getElementById('favicon');
  if (!linkEl) {
    const link = document.createElement('link');
    link.type = 'image/svg+xml';
    link.rel = 'shortcut icon mask-icon';
    link.id = 'favicon';

    link.href = favicon;

    document.getElementsByTagName('head')[0].appendChild(link);
  }
};

const isServerSide = (): boolean =>
  typeof window === 'undefined' && typeof document === 'undefined';

export { useSetMetaProperty, setFavicon, isServerSide };
