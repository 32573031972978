import React from 'react';
import { Link } from 'react-router-dom';
import {
  KText,
  KHeading,
  KLink,
  TextProps,
  HeadingProps,
} from '@xenzonegroup/kompass';
import {
  documentToReactComponents,
  NodeRenderer,
} from '@contentful/rich-text-react-renderer';
import {
  Document,
  BLOCKS,
  INLINES,
  Block,
  Inline,
} from '@contentful/rich-text-types';

import styles from './richtext.scss';

const renderAsset: NodeRenderer = asset => {
  return (
    <img
      src={asset.data.target.fields.file.url}
      alt={asset.data.target.fields.description || ''}
    />
  );
};

const renderAssetWithCaption: NodeRenderer = asset => {
  return (
    <figure>
      <img src={asset.data.target.fields.file.url} alt="" />

      <figcaption>
        <KText textStyle="small_text">
          {asset.data.target.fields.description}
        </KText>
      </figcaption>
    </figure>
  );
};

const renderHyperlink: NodeRenderer = (link, children) => {
  if (link.data.uri.startsWith('/')) {
    return (
      <KLink
        renderReactRouterLinkInstead={Link}
        to={{
          pathname: link.data.uri,
          state: { shouldMoveFocusToPageH1: true },
        }}
      >
        {children}
      </KLink>
    );
  } else {
    return <KLink href={link.data.uri}>{children}</KLink>;
  }
};

const renderParagraph = (
  textColour: TextProps['colour'],
  baseTextStyle?: TextProps['textStyle']
): NodeRenderer => (_, children): React.ReactNode => (
  <KText colour={textColour} textStyle={baseTextStyle}>
    {children}
  </KText>
);

const extractString = (node: React.ReactNode): string => {
  if (!node) return '';

  if (typeof node === 'string') return node;

  if (React.isValidElement(node)) return extractString(node.props.children);

  if (Array.isArray(node)) return node.map(el => extractString(el)).join(' ');

  return node.toString();
};

const slugify = (node: React.ReactNode): string => {
  if (!node) return '';

  const innerString = extractString(node);

  // Handle cases where this comes in as an array
  return innerString
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

const renderHeading2 = (
  textColour: HeadingProps['colour'],
  renderHeadingsAsText: boolean
): NodeRenderer => (_, children): React.ReactNode => {
  const id = slugify(children);

  return renderHeadingsAsText ? (
    <KText colour={textColour} textStyle="heading_M">
      <span className={styles.anchor} id={id}></span>
      {children}
    </KText>
  ) : (
    <KHeading colour={textColour} level={2} textStyle="heading_M">
      <span className={styles.anchor} id={id}></span>
      {children}
    </KHeading>
  );
};

const renderHeading3 = (
  textColour: HeadingProps['colour'],
  renderHeadingsAsText: boolean
): NodeRenderer => (_, children): React.ReactNode => {
  const id = slugify(children);
  return renderHeadingsAsText ? (
    <KText colour={textColour} textStyle="heading_S">
      <span className={styles.anchor} id={id}></span>
      {children}
    </KText>
  ) : (
    <KHeading colour={textColour} level={3} textStyle="heading_S">
      <span className={styles.anchor} id={id}></span>
      {children}
    </KHeading>
  );
};

const renderPullQuote = (node: Block | Inline): React.ReactNode => {
  const { quote } = node.data.target.fields;
  return (
    <blockquote>
      <KText colour="interaction_active_or_visited" textStyle="heading_M">
        {quote}
      </KText>
    </blockquote>
  );
};

const renderEmbeddedEntry: NodeRenderer = (node: Block | Inline) => {
  const entryType = node.data.target.sys.contentType.sys.id;
  if (entryType === 'pullquote') {
    return renderPullQuote(node);
  }
};

export const RichText: React.FC<{
  content: Document;
  textColour?: TextProps['colour'];
  renderHeadingsAsText?: boolean;
  renderImagesWithCaptions?: boolean;
  baseTextStyle?: TextProps['textStyle'];
}> = ({
  content,
  textColour,
  renderHeadingsAsText = false,
  renderImagesWithCaptions = false,
  baseTextStyle,
}) => {
  const renderP = renderParagraph(textColour, baseTextStyle);
  const renderH2 = renderHeading2(textColour, renderHeadingsAsText);
  const renderH3 = renderHeading3(textColour, renderHeadingsAsText);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: renderP,
      [BLOCKS.HEADING_2]: renderH2,
      [BLOCKS.HEADING_3]: renderH3,
      [BLOCKS.EMBEDDED_ASSET]: renderImagesWithCaptions
        ? renderAssetWithCaption
        : renderAsset,
      [INLINES.HYPERLINK]: renderHyperlink,
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
    },
  };

  return <>{documentToReactComponents(content, options)}</>;
};
