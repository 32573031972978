import React from 'react';
import { Entry } from 'contentful';
import { Link } from 'react-router-dom';
import { KText, KHeading, KIconLink } from '@xenzonegroup/kompass';

import { ArticleType } from '../pages/Article';

import styles from './featured-case-study.scss';

const FeaturedCaseStudy: React.FC<{
  content: Entry<ArticleType>;
}> = ({ content }) => (
  <div className={styles.featuredCaseStudy}>
    <div className={styles.contentWrapper}>
      <KHeading level={2} textStyle="heading_S">
        Case studies
      </KHeading>

      <div className={styles.content}>
        {content.fields.thumbnailImage && (
          <img
            className={`${styles.image} ${styles.imageMobile}`}
            src={content.fields.thumbnailImage.fields.file.url}
            alt={content.fields.thumbnailImage.fields.description || ''}
          />
        )}

        <div className={styles.text}>
          <KHeading
            level={3}
            textStyle="heading_M"
            id="featured_case_study_heading"
          >
            {content.fields.title}
          </KHeading>
          {!!content.fields.summary && (
            <KText textStyle="body_large">{content.fields.summary}</KText>
          )}
          <KIconLink
            icon="forward"
            label="Read full case study"
            labelPosition="left"
            colour="interaction"
            renderReactRouterLinkInstead={Link}
            to={`/article/${content.fields.slug}`}
            id="featured_case_study_link"
            aria-labelledby="featured_case_study_link featured_case_study_heading"
          />
        </div>
      </div>
    </div>

    {content.fields.thumbnailImage && (
      <img
        className={`${styles.image} ${styles.imageDesktop}`}
        src={content.fields.thumbnailImage.fields.file.url}
        alt={content.fields.thumbnailImage.fields.description || ''}
      />
    )}
  </div>
);

export { FeaturedCaseStudy };
