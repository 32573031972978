import { Asset, Entry, EntryCollection } from 'contentful';

type EntryLinkType = 'Entry';
type AssetLinkType = 'Asset';

type LinkType = EntryLinkType | AssetLinkType;

interface EntryLink {
  sys: {
    type: 'Link';
    linkType: EntryLinkType;
    id: string;
  };
}

interface AssetLink {
  sys: {
    type: 'Link';
    linkType: AssetLinkType;
    id: string;
  };
}

type IncludesContent = Pick<EntryCollection<any>, 'includes'>;

export interface ResolveContentModelLinksWith extends IncludesContent {
  Entry?: Array<Entry<any>>;
  Asset?: Array<Asset>;
}

const set = (
  obj: Record<string, any>,
  path: Array<string>,
  value: any
): void => {
  path.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === path.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

const isNotArrayOrObject = (val: any): boolean => {
  return typeof val !== 'object';
};

const isEntryLinkType = (entry: any): entry is EntryLink => {
  return Boolean(
    entry.sys?.type === 'Link' &&
      entry.sys?.linkType === 'Entry' &&
      entry.sys?.id
  );
};

const isAssetLinkType = (asset: any): asset is AssetLink => {
  return Boolean(
    asset.sys?.type === 'Link' &&
      asset.sys?.linkType === 'Asset' &&
      asset.sys?.id
  );
};

const resolveLink = (
  id: string,
  resolveContentModelLinksWith: ResolveContentModelLinksWith,
  linkType: LinkType
): Entry<any> | Asset => {
  return (resolveContentModelLinksWith[linkType] as Array<
    Entry<any> | Asset
  >).filter((link: Entry<any> | Asset) => link.sys.id === id)[0];
};

const breadthFirstSearchResolveContentLinks = (
  contentModel: Record<string, any>,
  resolveContentModelWith: ResolveContentModelLinksWith,
  resolveDepth: number,
  count = 0
): Record<string, any> => {
  const queue: Array<any> = [{ parentNodePath: [], currentNode: contentModel }];
  let result: Record<string, any> = {};

  while (queue.length) {
    const { parentNodePath, currentNode } = queue.shift();

    if (!parentNodePath.length) {
      const resolvedContent = resolveContentModelLinks(
        currentNode,
        resolveContentModelWith,
        queue,
        parentNodePath
      );

      result = { ...result, ...resolvedContent };
    } else {
      const resolvedContent = resolveContentModelLinks(
        currentNode,
        resolveContentModelWith,
        queue,
        parentNodePath
      );
      set(result, parentNodePath, resolvedContent);
    }
  }
  if (count < resolveDepth) {
    const updatedCount = count + 1;
    result = breadthFirstSearchResolveContentLinks(
      result,
      resolveContentModelWith,
      resolveDepth,
      updatedCount
    );
  }
  return result;
};

const resolveContentModelLinks = (
  contentModel: Record<string, any>,
  resolveContentModelLinksWith: ResolveContentModelLinksWith,
  queue: Array<any>,
  parentNodePath: Array<string>
): Record<string, any> => {
  const entries: Iterable<readonly [PropertyKey, any]> = Object.entries(
    contentModel
  ).map(([key, val]) => {
    if (isNotArrayOrObject(val)) {
      return [key, val];
    }
    if (isEntryLinkType(val)) {
      const { id } = val.sys;

      const resolvedEntry = resolveLink(
        id,
        resolveContentModelLinksWith,
        'Entry'
      );
      return [key, resolvedEntry];
    }
    if (isAssetLinkType(val)) {
      const { id } = val.sys;

      const resolvedAsset = resolveLink(
        id,
        resolveContentModelLinksWith,
        'Asset'
      );
      return [key, resolvedAsset];
    }
    if (Array.isArray(val)) {
      const values = val.map((v, index) => {
        if (isNotArrayOrObject(v)) {
          return v;
        }
        if (isEntryLinkType(v)) {
          const { id } = v.sys;
          return resolveLink(id, resolveContentModelLinksWith, 'Entry');
        }
        if (isAssetLinkType(v)) {
          const { id } = v.sys;
          return resolveLink(id, resolveContentModelLinksWith, 'Asset');
        }
        const nodePath = [...parentNodePath, key, index];
        queue.push({ parentNodePath: nodePath, currentNode: v });
        return v;
      });
      return [key, values];
    }
    const nodePath = [...parentNodePath, key];
    queue.push({ parentNodePath: nodePath, currentNode: val });
    return [key, val];
  });
  return Object.fromEntries(entries);
};

export { breadthFirstSearchResolveContentLinks };
