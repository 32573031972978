import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { Link } from 'react-router-dom';
import { KHeading, KText, KIconLink } from '@xenzonegroup/kompass';
import { RichText } from '../components/RichText';
import { Document } from '@contentful/rich-text-types';
import { parseISO, format } from 'date-fns';

const DATE_FORMAT = 'd LLLL, yyyy';

import styles from './article.scss';

interface BackLinkType {
  linkText: EntryFields.Text;
  url: EntryFields.Text;
}

export interface ArticleType {
  title: EntryFields.Text;
  intro?: EntryFields.Text;
  content: Document;
  articleType?: EntryFields.Text;
  image?: EntryFields.Object;
  summary: EntryFields.Text;
  date?: EntryFields.Date;
  contentInfo?: EntryFields.Text;
  slug: EntryFields.Text;
  thumbnailImage: EntryFields.Object;
  backLink?: Entry<BackLinkType>;
}

const Article: React.FC<{ content: Entry<ArticleType> }> = ({ content }) => {
  return (
    <section>
      <div
        className={`${styles.article} ${content.fields.articleType &&
          styles[content.fields.articleType]}`}
      >
        <header>
          <KHeading level={1} textStyle="heading_L">
            {content.fields.title}
          </KHeading>
          {content.fields.articleType === 'Person' && (
            <KText inline>{content.fields.summary}</KText>
          )}
          {content.fields.intro && (
            <KText textStyle="large_text">{content.fields.intro}</KText>
          )}
          {content.fields.date && content.fields.articleType !== 'Person' && (
            <KText textStyle="small_text">
              {format(parseISO(content.fields.date), DATE_FORMAT)}
            </KText>
          )}
        </header>
        {content.fields.image && (
          <img
            src={content.fields.image?.fields.file.url}
            alt={content.fields.image?.fields.description || ''}
          />
        )}
        <article>
          <RichText content={content.fields.content} renderImagesWithCaptions />
        </article>
        {content.fields.contentInfo && (
          <footer aria-label="article">
            <KText bold={true}>{content.fields.contentInfo}</KText>
          </footer>
        )}
      </div>
    </section>
  );
};

export { Article };
