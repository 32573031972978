import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import { isServerSide } from '../../utils';

/* These functions are overwritten later on when we we set up the Provider,
so it's safe to ignore these for now */
/* istanbul ignore next */
const initialState = {
  isLinkExpanded: (id: string): boolean => false,
  toggleLinkExpanded: (id: string): string => id,
};

export const dropdownStore = React.createContext(initialState);
const { Provider } = dropdownStore;

interface DropdownProviderProps extends RouteComponentProps {
  children?: React.ReactNode;
  history: History;
  containerRef: React.MutableRefObject<any>;
}

const DropdownProviderWithoutRouter: React.FC<DropdownProviderProps> = ({
  children,
  history,
  containerRef,
}) => {
  const [currentLinkExpandedId, setCurrentLinkExpandedId] = React.useState('');

  const isLinkExpanded = (id: string): boolean => currentLinkExpandedId === id;

  const toggleLinkExpanded = (id: string): string => {
    isLinkExpanded(id)
      ? setCurrentLinkExpandedId('')
      : setCurrentLinkExpandedId(id);
    return id;
  };

  const listenForURLChange = (): void => {
    setCurrentLinkExpandedId('');
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Escape') {
      setCurrentLinkExpandedId('');
    }
  };

  const onClick = (event: MouseEvent): void => {
    const dropdownElement = containerRef.current;

    if (
      dropdownElement &&
      !dropdownElement.contains(event.target as HTMLElement)
    ) {
      setCurrentLinkExpandedId('');
    }
  };

  React.useEffect(() => {
    const unregisterHistoryListen = history.listen(listenForURLChange);

    if (!isServerSide()) {
      window.addEventListener('keydown', onKeyDown);
      window.addEventListener('click', onClick);
    }

    return () => {
      if (!isServerSide()) {
        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('click', onClick);
      }
      unregisterHistoryListen();
    };
  }, []);

  return (
    <Provider
      value={{
        isLinkExpanded,
        toggleLinkExpanded,
      }}
    >
      {children}
    </Provider>
  );
};

export const DropdownProvider = withRouter<
  DropdownProviderProps,
  React.FC<DropdownProviderProps>
>(DropdownProviderWithoutRouter);
