import { NavigationLinkType } from './NavigationLink';
import { Location } from 'history';
import { NavigationBarType } from './NavigationBar';
import { Entry } from 'contentful';

const isNavigationLink = (
  link: NavigationLinkType | NavigationBarType
): link is NavigationLinkType => {
  return (link as NavigationLinkType).url !== undefined;
};

export const currentUrlContainedInNavBar = (
  link: Entry<NavigationLinkType | NavigationBarType>,
  location: Location
): boolean => {
  if (isNavigationLink(link.fields)) {
    return location.pathname === link.fields.url;
  } else {
    return link.fields.links.reduce(
      (acc: boolean, curr) =>
        acc || currentUrlContainedInNavBar(curr, location),
      false
    );
  }
};
