import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Entry, EntryFields } from 'contentful';
import { KHeading, KText, KLink } from '@xenzonegroup/kompass';

import styles from './contact-us.scss';

interface ContactType {
  name: EntryFields.Text;
  phoneNumber?: EntryFields.Text;
  email?: EntryFields.Text;
}

interface AddressType {
  name: EntryFields.Text;
  address: EntryFields.Text;
}

interface ContactUsPageType {
  title: EntryFields.Text;
  strapline: EntryFields.Text;
  contactGuidance?: EntryFields.Text;
  contacts: Entry<ContactType>[];
  addresses: Entry<AddressType>[];
  seoDescription: EntryFields.Text;
}

interface ContactUsProps {
  content: Entry<ContactUsPageType>;
  className: string;
}

const UnstyledContactUs: React.FC<ContactUsProps> = ({
  content,
  className,
}) => {
  const {
    title,
    strapline,
    contactGuidance,
    contacts,
    addresses,
  } = content.fields;

  return (
    <div className={`${className} ${styles.contactUs}`}>
      <nav aria-label="Breadcrumb">
        <KText textStyle="heading_S" aria-current="page" inline>
          {title}
        </KText>
      </nav>

      <KHeading level={1} textStyle="heading_L">
        {strapline}
      </KHeading>

      {contactGuidance && <KText>{contactGuidance}</KText>}

      <section className={styles.contacts}>
        <KHeading level={2} visuallyHidden={true} textStyle="heading_M">
          Contacts
        </KHeading>

        {contacts.map((contact: Entry<ContactType>) => (
          <div key={contact.sys.id} className={styles.contact}>
            <KHeading level={3} textStyle="button">
              {contact.fields.name}
            </KHeading>
            {contact.fields.phoneNumber && (
              <KText inline>{contact.fields.phoneNumber}</KText>
            )}
            {contact.fields.email && (
              <KLink href={`mailto:${contact.fields.email}`}>
                {contact.fields.email}
              </KLink>
            )}
          </div>
        ))}
      </section>

      <section className={styles.addresses}>
        <KHeading level={2} visuallyHidden={true} textStyle="heading_M">
          Our Offices
        </KHeading>

        {addresses.map((address: Entry<AddressType>) => (
          <div key={address.sys.id} className={styles.address}>
            <KHeading level={3} textStyle="heading_S">
              {address.fields.name}
            </KHeading>
            <address>
              {address.fields.address.split('\n').map(line => (
                <Fragment key={line}>
                  {line}
                  <br />
                </Fragment>
              ))}
            </address>
          </div>
        ))}
      </section>
    </div>
  );
};

const ContactUs = styled(UnstyledContactUs)`
  address {
    font-family: ${props => props.theme.textStyles.body.fontFamily};
    font-style: normal;
    line-height: 1.6;
  }

  > section:first-of-type {
    h3 {
      font-family: ${props => props.theme.textStyles.body.fontFamily};
    }
  }
`;

export default ContactUs;
