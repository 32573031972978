import React from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

/* These functions are overwritten later on when we we set up the Provider,
so it's safe to ignore these for now */
/* istanbul ignore next */
const initialState = {
  modalIsOpen: false,
  openModal: (): boolean => true,
  closeModal: (): boolean => false,
};

export const navigationStore = React.createContext(initialState);
const { Provider } = navigationStore;

interface NavigationProviderProps {
  children?: React.ReactNode;
  history: History;
}

const NavigationProviderWithoutRouter: React.FC<NavigationProviderProps> = ({
  children,
  history,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = (): boolean => {
    setModalIsOpen(true);
    return modalIsOpen;
  };

  const closeModal = (): boolean => {
    setModalIsOpen(false);
    return modalIsOpen;
  };

  const listenForURLChange = (): void => {
    setModalIsOpen(false);
  };

  React.useEffect(() => {
    history.listen(listenForURLChange);
  }, []);

  return (
    <Provider
      value={{
        modalIsOpen,
        openModal,
        closeModal,
      }}
    >
      {children}
    </Provider>
  );
};

export const NavigationProvider = withRouter(NavigationProviderWithoutRouter);
