import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading } from '@xenzonegroup/kompass';
import { Document } from '@contentful/rich-text-types';

import { RichText } from '../components/RichText';

import styles from './privacy-page.scss';

export interface PrivacyPageType {
  title: EntryFields.Text;
  slug: EntryFields.Text;
  content: Document;
}

const PrivacyPage: React.FC<{ content: Entry<PrivacyPageType> }> = ({
  content,
}) => {
  return (
    <section>
      <div className={styles.privacyPage}>
        <header>
          <KHeading level={1} textStyle="heading_L">
            {content.fields.title}
          </KHeading>
        </header>

        <article>
          <RichText content={content.fields.content} renderImagesWithCaptions />
        </article>
      </div>
    </section>
  );
};

export { PrivacyPage };
