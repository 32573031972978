import React, { useContext, useState, HTMLAttributes } from 'react';
import { KIconButton, KModalVideo } from '@xenzonegroup/kompass';
import { TextFriendlyBackgroundColourName } from '@xenzonegroup/kompass/lib_out/src/types/colours';
import { getDefaultTextColourForTextFriendlyBackgroundColour } from '@xenzonegroup/kompass/lib_out/src/styles/themes';
import { IconButtonProps } from '@xenzonegroup/kompass/lib_out/src/molecules/IconButtonAndLink/IconButton';
import { Entry, EntryFields } from 'contentful';
import { ThemeContext } from 'styled-components';

type PlayButtonIcons = 'play-big-alt-background' | 'play-big';

type BackgroundColorToPlayButtonIconMap = {
  [key in TextFriendlyBackgroundColourName]: PlayButtonIcons;
};

const backgroundColorToPlayButtonIconMap: Partial<BackgroundColorToPlayButtonIconMap> = {
  /* eslint-disable @typescript-eslint/camelcase */
  coloured_background_1: 'play-big',
  coloured_background_2: 'play-big',
  coloured_background_3: 'play-big',
  coloured_background_4: 'play-big-alt-background',
};

const getPlayButtonIconForBackgroundColour = (
  colour: TextFriendlyBackgroundColourName
): PlayButtonIcons => {
  return backgroundColorToPlayButtonIconMap[colour] || 'play-big';
};

interface VideoLinkType {
  linkText: EntryFields.Text;
  videoUrl: EntryFields.Text;
}

interface VideoLinkProps extends HTMLAttributes<HTMLButtonElement> {
  content: Entry<VideoLinkType>;
  backgroundColour?: TextFriendlyBackgroundColourName;
  playButton?: PlayButtonIcons;
  className?: string;
}

const VideoLink: React.FC<VideoLinkProps> = ({
  content,
  backgroundColour = 'elevated_background_level2',
  className,
  playButton,
  ...otherProps
}) => {
  const theme = useContext(ThemeContext);

  const [modalVideoIsOpen, setModalVideoOpenState] = useState(false);

  const openModalVideo = (): void => {
    setModalVideoOpenState(true);
  };

  const closeModalVideo = (): void => {
    setModalVideoOpenState(false);
  };

  const modalVideoTriggerButtonId = 'modal_video_trigger_button_d389y69';

  const videoSrcURLWithDoNotTrackParameter =
    content.fields.videoUrl + '?dnt=true';

  return (
    <>
      <KIconButton
        id={modalVideoTriggerButtonId}
        icon={
          playButton || getPlayButtonIconForBackgroundColour(backgroundColour)
        }
        label={content.fields.linkText}
        underline
        onClick={openModalVideo}
        colour={
          // TODO: Why have these colour prop types changed?
          getDefaultTextColourForTextFriendlyBackgroundColour(
            backgroundColour,
            theme
          ) as IconButtonProps['colour']
        }
        className={className}
        {...otherProps}
      />
      {modalVideoIsOpen && (
        <KModalVideo
          onClose={closeModalVideo}
          idOfTriggerButton={modalVideoTriggerButtonId}
          videoSrc={videoSrcURLWithDoNotTrackParameter}
        />
      )}
    </>
  );
};

export { VideoLink, VideoLinkType };
