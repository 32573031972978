import React from 'react';
import { Link } from 'react-router-dom';
import { KText, KButtonShapedLink, KHeading } from '@xenzonegroup/kompass';
import { EntryFields } from 'contentful';

import styles from './contact-us-module.scss';
import styled from 'styled-components';

interface ContactUsModuleProps {
  text: EntryFields.Text;
  backgroundColour?: string;
  className?: string;
}

const UnstyledContactUsModule: React.FC<ContactUsModuleProps> = ({
  text,
  backgroundColour,
  className,
}) => (
  <section
    className={`${styles.contactUsModule} ${backgroundColour ? className : ''}`}
  >
    <div className={styles.contentBlock}>
      <KHeading colour="primary_inverted" textStyle="heading_S" level={2}>
        Get in touch
      </KHeading>
      <KText
        className={styles.editableContent}
        colour="primary_inverted"
        textStyle="heading_L"
      >
        {text}
      </KText>
      <KButtonShapedLink
        styleVariation="pop"
        underline={true}
        renderReactRouterLinkInstead={Link}
        to={{
          pathname: '/contact-us',
          state: { shouldMoveFocusToPageH1: true },
        }}
      >
        Contact us
      </KButtonShapedLink>
    </div>
  </section>
);

const ContactUsModule = styled(UnstyledContactUsModule)`
  background-color: ${props => props.backgroundColour};
`;

export default ContactUsModule;
