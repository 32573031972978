import React from 'react';
import { useEffect } from 'react';
import { EntryFields, Entry } from 'contentful';
import { KIconButton } from '@xenzonegroup/kompass';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { mapContentTypeToComponent } from '../component-mapper';
import { currentUrlContainedInNavBar } from './currentUrlContainedInNavBar';
import { navigationStore } from './NavigationProvider';
import { NavigationLinkType } from './NavigationLink';
import { dropdownStore } from './DropdownProvider';
import styles from './navigation-bar.scss';

export interface NavigationBarType {
  displayName: EntryFields.Text;
  links: Entry<NavigationLinkType | NavigationBarType>[];
}

const UnstyledNavigationBar: React.FC<{
  content: Entry<NavigationBarType>;
  className?: string;
}> = ({ content, className }) => {
  const location = useLocation();

  const { modalIsOpen } = React.useContext(navigationStore);
  const { isLinkExpanded, toggleLinkExpanded } = React.useContext(
    dropdownStore
  );
  const isExpanded = isLinkExpanded(content.sys.id);
  const toggle = (): string => toggleLinkExpanded(content.sys.id);

  const currentClasses = `current-expanded ${styles.currentExpanded}`;

  useEffect((): void => {
    const anyLinksCurrent = currentUrlContainedInNavBar(content, location);
    if (anyLinksCurrent && !isExpanded && modalIsOpen) {
      toggleLinkExpanded(content.sys.id);
    }
  }, []);

  return (
    <div
      className={`${className} ${styles.navigationBar} ${
        modalIsOpen ? styles.modalIsOpen : styles.modalIsClosed
      }`}
    >
      <KIconButton
        onClick={toggle}
        icon={isExpanded ? 'arrow-up' : 'arrow-down'}
        label={content.fields.displayName}
        className={styles.overflowButton}
        labelPosition="left"
        colour="interaction"
        aria-expanded={isExpanded}
        aria-controls={`overflow-menu-${content.sys.id}`}
      />
      {isExpanded && (
        <ul
          id={`overflow-menu-${content.sys.id}`}
          className={styles.subNavigation}
        >
          {content.fields.links.map(link => {
            const Component = mapContentTypeToComponent(
              link.sys.contentType.sys.id
            );
            const isCurrent = currentUrlContainedInNavBar(link, location);
            return (
              <li
                key={link.sys.id}
                className={`${isCurrent ? currentClasses : ''} ${
                  styles.subNavigationLi
                }`}
                data-testid={`item-${link.sys.id}`}
              >
                <Component content={link} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export const NavigationBar = styled(UnstyledNavigationBar)`
  > ul {
    background-color: ${props =>
      props.theme.colours.text_friendly_background_colours
        .elevated_background_level2};
  }
  li.current-expanded {
    border-color: ${props => props.theme.colours.text_colours.interaction};
  }
`;
