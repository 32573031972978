import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading } from '@xenzonegroup/kompass';
import { Document } from '@contentful/rich-text-types';

import { RichText } from '../components/RichText';

import styles from './simple-page.scss';

export interface SimplePageType {
  title: EntryFields.Text;
  content: Document;
}

const SimplePage: React.FC<{ content: Entry<SimplePageType> }> = ({
  content,
}) => (
  <section>
    <div className={styles.simplePage}>
      <header>
        <KHeading level={1} textStyle="heading_L">
          {content.fields.title}
        </KHeading>
      </header>

      <article>
        <RichText content={content.fields.content} renderImagesWithCaptions />
      </article>
    </div>
  </section>
);

export { SimplePage };
