/* istanbul ignore file */
import 'ts-polyfill/lib/es2015-core';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2019-object';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { initFathom } from './utils/analyticsEvents';
import { App } from './App';
import { Entry } from 'contentful';
import { PageContainingNavigationBarAndFooter } from './components/PageWrapper';

declare global {
  interface Window {
    config: Config;
    __initial_content?: Entry<PageContainingNavigationBarAndFooter>;
    __initial_content_url?: string;
  }
}

initFathom();

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
