import React from 'react';
import {
  KHeading,
  KText,
  KButton,
  useSetPageTitle,
} from '@xenzonegroup/kompass';
import styles from './error-loading-page.scss';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';

const ErrorLoadingPage: React.FC<{ resetError?: () => void }> = ({
  resetError,
}) => {
  const history = useHistory();
  useSetPageTitle({
    pageTitle: 'Problem loading this page - Kooth plc',
    dependenciesArray: [],
    ignoreH1Errors: true,
    append: null,
  });
  return (
    <>
      <Header />
      <main className={styles.content}>
        <div className={styles.errorLoadingPage}>
          <div className={styles.errorLoadingContent}>
            <KHeading level={1}>
              Sorry, we’re having trouble loading this page.
            </KHeading>
            <KText textStyle="large_text">
              You can try again later or contact your service provider if this
              issue continues.
            </KText>
            <KButton
              label="Go back to previous page"
              styleVariation="pop"
              underline
              onClick={() => {
                if (resetError) {
                  resetError();
                }
                history.goBack();
              }}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export { ErrorLoadingPage };
