import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EntryFields, Entry } from 'contentful';
import styled, { ThemeContext } from 'styled-components';
import { KHeading, KButtonShapedLink } from '@xenzonegroup/kompass';
import { TextFriendlyBackgroundColourName } from '@xenzonegroup/kompass/lib_out/src/types/StyledComponentsDefaultTheme';
import { getDefaultTextColourForTextFriendlyBackgroundColour } from '@xenzonegroup/kompass/lib_out/src/styles/themes';
import { Document } from '@contentful/rich-text-types';

import { NavigationLinkType } from './NavigationMenu/NavigationLink';
import { RichText } from './RichText';
import { VideoLink, VideoLinkType } from './VideoLink';

import styles from './blurb-module.scss';

export interface BlurbModuleType {
  backgroundColour: TextFriendlyBackgroundColourName;
  title: EntryFields.Text;
  baseTextStyle: 'body' | 'large_text';
  fullBlurb: Document;
  callToAction?: Entry<NavigationLinkType>;
  videoLink?: Entry<VideoLinkType>;
  footerImages?: EntryFields.Object[];
}

const UnstyledBlurbModule: React.FC<{
  content: Entry<BlurbModuleType>;
  className?: string;
}> = ({ content, className }) => {
  const theme = useContext(ThemeContext);

  const textColour = getDefaultTextColourForTextFriendlyBackgroundColour(
    content.fields.backgroundColour,
    theme
  );

  return (
    <section className={className}>
      <div className={styles.blurbModule}>
        <div className={styles.heading}>
          <KHeading colour={textColour} level={2} textStyle="heading_S">
            {content.fields.title}
          </KHeading>
        </div>
        <div className={styles.blurb}>
          {content.fields.fullBlurb && (
            <RichText
              content={content.fields.fullBlurb}
              textColour={textColour}
              renderHeadingsAsText={true}
              renderImagesWithCaptions={false}
              baseTextStyle={content.fields.baseTextStyle}
            />
          )}
          {content.fields.videoLink && (
            <div>
              <VideoLink
                content={content.fields.videoLink}
                backgroundColour={content.fields.backgroundColour}
              />
            </div>
          )}
          {content.fields.callToAction && (
            <div>
              <KButtonShapedLink
                styleVariation="pop"
                underline={true}
                renderReactRouterLinkInstead={Link}
                to={content.fields.callToAction.fields.url}
              >
                {content.fields.callToAction.fields.linkText}
              </KButtonShapedLink>
            </div>
          )}
          {content.fields.footerImages && (
            <div className={styles.footerImages}>
              {content.fields.footerImages.map(image => (
                <img
                  key={image.sys.id}
                  alt={image.fields.description}
                  src={image.fields.file.url}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const BlurbModule = styled(UnstyledBlurbModule)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours[
      props.content.fields.backgroundColour
    ]};
`;
