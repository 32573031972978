import React from 'react';
import { Entry, Asset } from 'contentful';
import styles from './full-width-image.scss';

interface FullWidthImageType {
  largeImage?: Asset;
  mediumImage?: Asset;
  smallImage?: Asset;
}

const FullWidthImage: React.FC<{ content: Entry<FullWidthImageType> }> = ({
  content,
}) => {
  const { largeImage, mediumImage, smallImage } = content.fields;

  return (
    <section>
      <div>
        {largeImage && (
          <img
            src={largeImage.fields.file.url}
            alt={largeImage.fields.description}
            className={styles.largeImage}
          />
        )}
        {mediumImage && (
          <img
            src={mediumImage.fields.file.url}
            alt={mediumImage.fields.description}
            className={styles.mediumImage}
          />
        )}
        {smallImage && (
          <img
            src={smallImage.fields.file.url}
            alt={smallImage.fields.description}
            className={styles.smallImage}
          />
        )}
      </div>
    </section>
  );
};

export { FullWidthImage };
