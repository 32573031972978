import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { KLink, LinkProps } from '@xenzonegroup/kompass';
import { Entry, EntryFields } from 'contentful';

const ExternalLinkIcon: React.FC<{ id: string }> = ({ id }) => {
  const labelId = `external-link-${id}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      role="img"
      aria-labelledby={labelId}
    >
      <title id={labelId}>external link</title>
      <g transform="translate(-803.395 -856.572) rotate(45)">
        <path
          className="line"
          d="M0,0V8.7"
          transform="translate(1182.5 34.127)"
        />
        <path
          className="arrowhead"
          d="M788.1,350.92a.5.5,0,0,1-.365-.148l-3.038-3.238a.328.328,0,0,1-.049-.4.48.48,0,0,1,.415-.213h6.076a.48.48,0,0,1,.415.213.327.327,0,0,1-.049.4l-3.038,3.238A.5.5,0,0,1,788.1,350.92Z"
          transform="translate(1970.598 383.296) rotate(180)"
        />
      </g>
    </svg>
  );
};

export interface NavigationLinkType {
  linkText: EntryFields.Text;
  url: EntryFields.Text;
}

const UnstyledNavigationLink: React.FC<{
  content: Entry<NavigationLinkType>;
  className?: string;
  colour?: LinkProps['colour'];
}> = ({ content, className, ...props }) => {
  const location = useLocation();

  const isCurrentPage = location.pathname === content.fields.url;

  if (content.fields.url.startsWith('/')) {
    return (
      <KLink
        to={{
          pathname: content.fields.url,
          state: { shouldMoveFocusToPageH1: true },
        }}
        renderReactRouterLinkInstead={Link}
        aria-current={isCurrentPage && 'page'}
        className={className}
        standalone={true}
        useVisitedStyles={false}
        {...props}
      >
        {content.fields.linkText}
      </KLink>
    );
  }
  return (
    <KLink
      href={content.fields.url}
      className={className}
      // noreferrer not set here because we may want this information for analytics
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel="noopener"
      standalone={true}
      useVisitedStyles={false}
      {...props}
    >
      {content.fields.linkText} <ExternalLinkIcon id={content.sys.id} />
    </KLink>
  );
};

export const NavigationLink = styled(UnstyledNavigationLink)`
  text-decoration: none;

  display: inline-block;
  min-height: 2.5rem;
  min-width: 2.5rem;
  line-height: 2.5rem;

  font-family: 'Sarabun', system-fonts;
  font-weight: 600;

  .line {
    fill: none;
    stroke: ${props =>
      props.colour === 'primary_text_colour_inverted'
        ? props.theme.colours.text_colours.primary_inverted
        : props.theme.colours.text_colours.interaction};
    stroke-linecap: round;
    stroke-width: 2px;
  }

  .arrowhead {
    fill: ${props =>
      props.colour === 'primary_text_colour_inverted'
        ? props.theme.colours.text_colours.primary_inverted
        : props.theme.colours.text_colours.interaction};
  }

  &:hover {
    .line {
      stroke: ${props =>
        props.colour === 'primary_text_colour_inverted'
          ? props.theme.colours.text_colours.primary_inverted_hovered
          : props.theme.colours.text_colours.interaction_hovered};
    }

    .arrowhead {
      fill: ${props =>
        props.colour === 'primary_text_colour_inverted'
          ? props.theme.colours.text_colours.primary_inverted_hovered
          : props.theme.colours.text_colours.interaction_hovered};
    }
  }
`;
