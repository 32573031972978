import { useEffect, useState } from 'react';

/**
 * Looks for this meta tag in the HTML head: `<meta name="visitor-location-country" content="SOME_COUNTRY_CODE">`.
 * Then returns that country code.
 *
 * That meta tag is added when the initial HTML is generated server side.
 * The country code comes from a request header added by our Content Delivery Network.
 * (see `renderApp`).
 */
function useGetVisitorLocationCountryFromHtmlMetaTag(): string | undefined {
  const [
    visitorLocationCountryMetaTagContent,
    updateStateWithCorrectValue /* Note: this does NOT update the content of the meta tag itself */,
  ] = useState<string | undefined>(undefined);

  useEffect(function updateStateWithContentFromVisitorLocationCountryMetaTag() {
    const visitorLocationCountryMetaEl = document.head.querySelector(
      'meta[name="visitor-location-country"][content]'
    ) as HTMLMetaElement | undefined;

    if (!visitorLocationCountryMetaEl) return;

    updateStateWithCorrectValue(visitorLocationCountryMetaEl.content);
  }, []);

  return visitorLocationCountryMetaTagContent;
}

export { useGetVisitorLocationCountryFromHtmlMetaTag };
