import configProvider from '../utils/configProvider';
import { isServerSide } from '../utils';

const DEFAULT_INCLUDE_DEPTH = 3;

interface GetContentUrl {
  serverHostname?: string;
  includeDepth?: number;
  contentId?: string;
  slug?: string;
  contentType?: 'article' | 'privacyPage';
}

export const getContentUrl = ({
  contentType,
  slug,
  contentId,
  serverHostname,
  includeDepth = DEFAULT_INCLUDE_DEPTH,
}: GetContentUrl): string => {
  const { apiHostnames } = configProvider.config;
  let hostname: string;
  if (isServerSide()) {
    hostname = serverHostname || 'localhost';
  } else {
    hostname = window.location.hostname;
  }

  const path = hostname.includes('draft') ? 'draft' : 'content';

  const isGettingContentWithSlug = contentType && slug;

  return isGettingContentWithSlug
    ? `${apiHostnames.content}/${path}/corporate/${contentType}/${slug}?include=${includeDepth}`
    : `${apiHostnames.content}/${path}/corporate/page/${contentId}?include=${includeDepth}`;
};
