import { useState, useEffect } from 'react';

import { isServerSide } from '../utils';

import { fetchContent } from './fetchContent';
import * as Sentry from '@sentry/react';
import { resolveContentLinks } from './resolveContentLinks';

const getInitialState = <T>(
  serverContent: T | undefined,
  url: string
): T | undefined => {
  if (isServerSide()) {
    return serverContent;
  } else if (window.__initial_content_url === url) {
    return window.__initial_content as T | undefined;
  }
};

const getInitialErrorState = <T>(serverContent: T | undefined): boolean => {
  if (isServerSide() && !serverContent) {
    return true;
  }
  return false;
};

export const useGetContent = <T>(
  url: string,
  serverContent?: T
): { data?: T; isError: boolean } => {
  const [data, setData] = useState<T | undefined>(
    getInitialState<T>(serverContent, url)
  );
  const [isError, setIsError] = useState(getInitialErrorState(serverContent));

  useEffect(() => {
    if (isServerSide()) {
      setIsError(serverContent === undefined);
    } else {
      if (window.__initial_content && url === window.__initial_content_url) {
        delete window.__initial_content;
        delete window.__initial_content_url;
      } else {
        fetchContent<T>(url)
          .then(data => {
            const dataWithResolvedLinks = resolveContentLinks(url, data);
            setData(dataWithResolvedLinks as T);
          })
          .catch(error => {
            Sentry.captureException(error);
            setIsError(true);
          });
      }
    }
  }, [url]);

  return { data, isError };
};
