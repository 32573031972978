import React from 'react';
import { KText, useSetPageTitle } from '@xenzonegroup/kompass';
import SpinnerIllustration from '../images/illustration-loading.svg';
import styles from './loading-spinner.scss';

export const Spinner: React.FC = () => (
  <img className={styles.spinner} alt="" src={SpinnerIllustration} />
);

export const LoadingSpinner: React.FC = () => (
  <div className={styles.loadingSpinner}>
    <Spinner />
    <KText textStyle="heading_XS">Loading</KText>
  </div>
);

export const FullPageLoadingSpinner: React.FC = () => {
  useSetPageTitle({
    pageTitle: 'Loading',
    ignoreH1Errors: true,
  });

  return (
    <div className={styles.fullPageLoadingSpinner}>
      <LoadingSpinner />
    </div>
  );
};
