import React, { useContext } from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading, KText, KIconLink } from '@xenzonegroup/kompass';
import styled, { ThemeContext } from 'styled-components';

import { NavigationLinkType } from './NavigationMenu/NavigationLink';

import styles from './description-list.scss';
import { getDefaultTextColourForTextFriendlyBackgroundColour } from '@xenzonegroup/kompass/lib_out/src/styles/themes';
import { TextFriendlyBackgroundColourName } from '@xenzonegroup/kompass/lib_out/src/types/StyledComponentsDefaultTheme';
import { Link } from 'react-router-dom';

export interface DescriptionListItemType {
  name: EntryFields.Text;
  title: EntryFields.Text;
  description: EntryFields.Text;
  link?: Entry<NavigationLinkType>;
}

interface BlockQuoteType {
  blockQuote: EntryFields.Text;
  blockQuoteLinkText?: EntryFields.Text;
  blockQuoteLink?: EntryFields.Text;
  blockQuoteImage?: EntryFields.Object;
}

export interface DescriptionListType extends BlockQuoteType {
  name: EntryFields.Text;
  style: 'linear' | 'tiled';
  backgroundColour: TextFriendlyBackgroundColourName;
  title: EntryFields.Text;
  blurb?: EntryFields.Text;
  descriptionListItems: Entry<DescriptionListItemType>[];
  quoteMessage?: EntryFields.Text;
  quoteSource?: EntryFields.Text;
  quoteSpeechBubbleColour?: EntryFields.Text;
}

interface DescriptionListProps {
  content: Entry<DescriptionListType>;
  className?: string;
}

const getSpeechBubbleColourStyles = (colour?: string): string => {
  if (colour === 'green') {
    return styles.greenQuoteBox;
  }
  // Default behavior is a blue speech bubble box
  return '';
};

const BlockQuote = ({
  blockQuote,
  blockQuoteLinkText,
  blockQuoteLink,
  blockQuoteImage,
}: BlockQuoteType): React.ReactElement => (
  <div className={styles.blockquote}>
    <div>
      <blockquote>
        <KText colour="coloured_text_1" textStyle="heading_M">
          {blockQuote}
        </KText>
      </blockquote>

      {blockQuoteLinkText && blockQuoteLink && (
        <KIconLink
          label={blockQuoteLinkText}
          labelPosition="left"
          icon="forward"
          to={blockQuoteLink}
          renderReactRouterLinkInstead={Link}
          colour="primary_inverted"
        />
      )}
    </div>

    {blockQuoteImage && (
      <img
        className={styles.blockQuoteImage}
        src={blockQuoteImage.fields.file.url}
        alt={blockQuoteImage.fields.description || ''}
      />
    )}
  </div>
);

const UnstyledDescriptionList: React.FC<DescriptionListProps> = ({
  content,
  className,
}) => {
  const {
    style,
    backgroundColour,
    title,
    blurb,
    descriptionListItems,
    quoteMessage,
    quoteSource,
    quoteSpeechBubbleColour,
    blockQuote,
    blockQuoteLinkText,
    blockQuoteLink,
    blockQuoteImage,
  } = content.fields;

  const theme = useContext(ThemeContext);

  const colour = getDefaultTextColourForTextFriendlyBackgroundColour(
    backgroundColour,
    theme
  );

  // add empty items in tiled style to fill the last row so that the columns line up
  const gridWidth = blockQuote ? 3 : 4;
  const itemsInLastRow = descriptionListItems.length % gridWidth;
  const emptyItems = (gridWidth - itemsInLastRow) % gridWidth;

  // split out in order to render the blockquote
  const firstLine = descriptionListItems.slice(0, gridWidth);
  const restOfItems = descriptionListItems.slice(
    gridWidth,
    descriptionListItems.length
  );

  const renderDescriptionListItem = (
    item: Entry<DescriptionListItemType>
  ): React.ReactElement => (
    <div
      key={item.fields.name}
      className={`${styles.descriptionListItem} ${
        styles['descriptionListItem-' + gridWidth]
      }`}
    >
      <dt>
        <KText
          textStyle={style === 'tiled' ? 'heading_S' : 'heading_L'}
          colour={colour}
        >
          {item.fields.title}
        </KText>
      </dt>
      <dd>
        <KText
          colour={colour}
          textStyle={style === 'tiled' ? 'body' : 'body_large'}
        >
          {item.fields.description}
        </KText>
      </dd>
    </div>
  );

  return (
    <>
      <section
        className={`${className} ${styles.descriptionListWrapper} ${styles[style]}`}
      >
        <div className={styles.descriptionList}>
          <div className={styles.descriptionListBlurb}>
            <KHeading
              level={2}
              textStyle={style === 'tiled' ? 'heading_L' : 'heading_S'}
              colour={colour}
            >
              {title}
            </KHeading>
            {blurb &&
              blurb.split('\n').map((line, i) => {
                if (line.trim() != '') {
                  return (
                    <KText
                      colour={getDefaultTextColourForTextFriendlyBackgroundColour(
                        backgroundColour,
                        theme
                      )}
                      textStyle="large_text"
                      key={i}
                    >
                      {line}
                    </KText>
                  );
                }
              })}
          </div>
          <dl className={styles.descriptionListItems}>
            {firstLine.map(renderDescriptionListItem)}
            {blockQuote && (
              <BlockQuote
                blockQuote={blockQuote}
                blockQuoteLinkText={blockQuoteLinkText}
                blockQuoteLink={blockQuoteLink}
                blockQuoteImage={blockQuoteImage}
              />
            )}
            {restOfItems.map(renderDescriptionListItem)}
            {style === 'tiled' &&
              Array.from(Array(emptyItems), (_, i) => (
                <div key={i} className={styles.emptyDescriptionListItem}></div>
              ))}
          </dl>
        </div>
      </section>
      {quoteSource && quoteMessage ? (
        <aside className={styles.quoteBoxWrapper} aria-label="quote">
          <blockquote
            className={`${styles.quoteBox} ${getSpeechBubbleColourStyles(
              quoteSpeechBubbleColour
            )}`}
          >
            <KText colour={colour} textStyle="heading_S">
              {quoteMessage}
            </KText>
            <KText inline colour="coloured_text_1" textStyle="heading_XS">
              {quoteSource}
            </KText>
          </blockquote>
        </aside>
      ) : (
        <div className={styles.illustration} />
      )}
    </>
  );
};

export const DescriptionList = styled(UnstyledDescriptionList)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours[
      props.content.fields.backgroundColour
    ]};
`;
