import React from 'react';
import { Link } from 'react-router-dom';
import { Entry, EntryFields } from 'contentful';
import { KText, KLink } from '@xenzonegroup/kompass';
import styled from 'styled-components';

import styles from './footer.scss';
import IconLogoWithText from '../images/icon-logo-with-text.svg';
import {
  NavigationLinkType,
  NavigationLink,
} from './NavigationMenu/NavigationLink';

interface SocialMediaLinkType {
  linkText: EntryFields.Text;
  url: EntryFields.Text;
  socialMediaIcon: EntryFields.Object; // Is this the right type?
}

export interface FooterType {
  socialMediaLinks: Entry<SocialMediaLinkType>[];
  footerLinks: Entry<NavigationLinkType>[];
}

interface FooterProps {
  content: Entry<FooterType>;
  className?: string;
}

const UnstyledFooter: React.FC<FooterProps> = ({ className, content }) => {
  return (
    <footer className={`${styles.footerContainer} ${className}`}>
      <div className={styles.footer}>
        <nav aria-label="footer">
          <KLink
            renderReactRouterLinkInstead={Link}
            to={{
              pathname: '/',
              state: { shouldMoveFocusToPageH1: true },
            }}
          >
            <img src={IconLogoWithText} alt="Home" />
          </KLink>

          <div className={styles.siteLinks}>
            {content?.fields.footerLinks?.map(
              (link: Entry<NavigationLinkType>, idx: number) => (
                <NavigationLink
                  key={`footer-link-${idx}`}
                  content={link}
                  colour="primary_text_colour_inverted"
                />
              )
            )}
          </div>

          <div className={styles.socialLinks}>
            {content?.fields.socialMediaLinks?.map(
              (link: Entry<SocialMediaLinkType>, idx: number) => (
                <KLink href={link.fields.url} key={`social-media-link-${idx}`}>
                  <img
                    src={link.fields.socialMediaIcon.fields.file.url}
                    alt={link.fields.linkText}
                  />
                </KLink>
              )
            )}
          </div>
        </nav>

        <KText colour="primary_inverted" className={styles.copyright}>
          &copy; {new Date().getFullYear()} Kooth plc
        </KText>
      </div>
    </footer>
  );
};

const Footer = styled(UnstyledFooter)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours.coloured_background_2};
  p {
    font-family: ${props => props.theme.textStyles.button.fontFamily};
    font-size: 0.875rem;
  }
`;

export default Footer;
