import React from 'react';
import { Entry, EntryFields } from 'contentful';
import { Link, useHistory } from 'react-router-dom';
import { KHeading, KText, KLink } from '@xenzonegroup/kompass';
import { VideoLinkType, VideoLink } from './VideoLink';

import styles from './people-module.scss';
import { ArticleType } from '../pages/Article';

export interface PeopleType {
  title: EntryFields.Text;
  blurb?: EntryFields.Text;
  video?: Entry<VideoLinkType>;
  people: Entry<ArticleType>[];
}

const PeopleList: React.FC<{
  content: Entry<PeopleType>;
  headingLevel: 2 | 3;
}> = ({ content, headingLevel }) => {
  const history = useHistory();
  return (
    <>
      <KHeading level={headingLevel} textStyle="heading_M">
        {content.fields.title}
      </KHeading>
      {content.fields.blurb && (
        <KText textStyle="body_large">{content.fields.blurb}</KText>
      )}
      {content.fields.video && (
        <VideoLink content={content.fields.video} className={styles.video} />
      )}
      <div className={styles.peopleList}>
        {content.fields.people.map(person => {
          return (
            <figure key={person.sys.id}>
              <img src={person.fields.thumbnailImage.fields.file.url} alt="" />
              <figcaption>
                <KLink
                  to={{
                    pathname: `${history.location.pathname}/${person.fields.slug}`,
                    state: { shouldMoveFocusToPageH1: true },
                  }}
                  renderReactRouterLinkInstead={Link}
                  textStyle="heading_XS"
                  useVisitedStyles={false}
                >
                  {person.fields.title}
                </KLink>
                {person.fields.summary && (
                  <KText>{person.fields.summary}</KText>
                )}
              </figcaption>
            </figure>
          );
        })}
      </div>
    </>
  );
};

const PeopleModule: React.FC<{ content: Entry<PeopleType> }> = ({
  content,
}) => (
  <section className={styles.peopleModule}>
    <div>
      <PeopleList content={content} headingLevel={2} />
    </div>
  </section>
);

export { PeopleModule, PeopleList };
