import React from 'react';
import { KText, KLink } from '@xenzonegroup/kompass';
import styles from './NewArticleBanner.styles.scss';
import { isServerSide } from '../../utils';

const NewArticleBanner: React.FC<{}> = () => {
  if (isServerSide()) return null; /* 1 */

  return (
    <div className={styles.NewArticleBanner}>
      <div className={styles.contentContainer}>
        <KText bold textStyle="heading_S">
          Latest - read{' '}
          <KLink
            href="https://koothplc.com/article/response-to-article-in-california"
            colour="primary_text_colour"
            useVisitedStyles={false}
            textStyle="heading_S"
          >
            our response
          </KLink>{' '}
          to the recent KFF article in California
        </KText>
      </div>
    </div>
  );
};

export { NewArticleBanner };

/**
 * Note 1
 *
 * Rendering NewArticleBanner only client side has a downside:
 * It will not appear in the HTML generated server side, and hence it'll only appear after the JS bundle has been executed by the client.
 * Concretely, that means that the banner will appear with a sub-1 second delay on the page.
 *
 * But that's a trade-off worth making in this case.
 * Because rendering that banner both server-side and client side would require adding quite a bit more complexity, which is not worth it in this case.
 */
