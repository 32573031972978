import React from 'react';
import { EntryFields, Entry } from 'contentful';
import { KHeading } from '@xenzonegroup/kompass';
import { PeopleType, PeopleList } from './PeopleModule';

import styles from './people-lists.scss';

interface PeopleListsType {
  title: EntryFields.Text;
  people: Entry<PeopleType>[];
}

const PeopleLists: React.FC<{ content: Entry<PeopleListsType> }> = ({
  content,
}) => (
  <section className={styles.peopleLists}>
    <div>
      <KHeading level={2}>{content.fields.title}</KHeading>
      {content.fields.people.map(people => (
        <div className={styles.list} key={people.sys.id}>
          <PeopleList content={people} headingLevel={3} />
        </div>
      ))}
    </div>
  </section>
);

export { PeopleLists };
