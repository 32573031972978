import React from 'react';
import { KText, KHeading } from '@xenzonegroup/kompass';
import { EntryFields, Entry } from 'contentful';

import styles from './textured-billboard.scss';

export interface TexturedBillboardType {
  name: EntryFields.Text;
  strapline?: EntryFields.Text;
  title: EntryFields.Text;
  mainText: EntryFields.Text;
}

export const TexturedBillboard: React.FC<{
  content: Entry<TexturedBillboardType>;
}> = ({ content }) => {
  const { strapline, title, mainText } = content.fields;

  return (
    <section className={styles.texturedBillboard}>
      <div>
        <KHeading level={2} textStyle="heading_S">
          {title}
        </KHeading>

        <KText textStyle="heading_L">{mainText}</KText>

        {strapline && <KText>{strapline}</KText>}
      </div>
    </section>
  );
};
