import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Entry, EntryFields, Asset } from 'contentful';
import { KText, KHeading, KButtonShapedLink } from '@xenzonegroup/kompass';

import styles from './info-page.scss';
import { mapContentTypeToComponent } from '../components/component-mapper';
import { BlurbModuleType } from '../components/BlurbModule';
import { NavigationLinkType } from '../components/NavigationMenu/NavigationLink';
import ContactUsModule from '../components/ContactUsModule';

type AvailableSectionTypes = BlurbModuleType;
type HeroIllustrationStyle =
  | 'floating'
  | 'cut-off'
  | 'large-cut-off'
  | 'peeking-over-the-top'
  | 'full-width';

export interface InfoPageType {
  breadcrumb: EntryFields.Text;
  title: EntryFields.Text;
  strapline?: EntryFields.Text;
  heroIllustration?: Asset;
  heroIllustrationStyle?: HeroIllustrationStyle;
  sections?: Entry<AvailableSectionTypes>[];
  contactUsText?: EntryFields.Text;
  callToActionButton?: Entry<NavigationLinkType>;
}

interface HeaderProps {
  breadcrumb: string;
  title: string;
  strapline?: EntryFields.Text;
  heroIllustration?: Asset;
  heroIllustrationStyle?: HeroIllustrationStyle;
  className?: string;
  callToActionButton?: NavigationLinkType;
}

const UnstyledHeader: React.FC<HeaderProps> = ({
  breadcrumb,
  title,
  strapline,
  className,
  callToActionButton,
  heroIllustrationStyle,
  heroIllustration,
}) => (
  <section
    className={`${styles.header} ${className} ${heroIllustrationStyle ||
      styles.noHeroIllustrationStyle}`}
  >
    <div>
      <nav aria-label="Breadcrumb">
        <KText
          textStyle="heading_S"
          aria-current="page"
          inline
          colour={
            heroIllustrationStyle === 'full-width'
              ? 'primary_inverted'
              : 'primary'
          }
        >
          {breadcrumb}
        </KText>
      </nav>
      {heroIllustration && heroIllustration.fields.description && (
        <img
          src={heroIllustration.fields.file.url}
          alt={heroIllustration.fields.description}
          className="visually_hidden"
        />
      )}
      <KHeading
        textStyle={
          heroIllustrationStyle === 'large-cut-off' ? 'heading_XL' : 'heading_L'
        }
        level={1}
        colour={
          heroIllustrationStyle === 'full-width'
            ? 'primary_inverted'
            : 'primary'
        }
      >
        {title}
      </KHeading>
      {strapline && <KText textStyle="large_text">{strapline}</KText>}

      {callToActionButton && (
        <KButtonShapedLink
          styleVariation="pop"
          underline
          href={callToActionButton.url}
        >
          {callToActionButton.linkText}
        </KButtonShapedLink>
      )}
    </div>
  </section>
);

const Header = styled(UnstyledHeader)`
  ${props =>
    props.heroIllustration &&
    css`
    background-image: url('${props.heroIllustration.fields.file.url}');
    `}
`;

const InfoPage: React.FC<{
  content: Entry<InfoPageType>;
}> = ({ content }) => {
  const theme = useContext(ThemeContext);
  const lastContentBackgroundColour = content.fields.sections
    ? content.fields.sections?.[content.fields.sections.length - 1].fields
        .backgroundColour
    : '';
  const contactUsBackgroundColour = lastContentBackgroundColour
    ? theme.colours.text_friendly_background_colours[
        lastContentBackgroundColour
      ]
    : '';

  return (
    <>
      <Header
        breadcrumb={content.fields.breadcrumb}
        title={content.fields.title}
        strapline={content.fields.strapline}
        heroIllustration={content.fields.heroIllustration}
        heroIllustrationStyle={content.fields.heroIllustrationStyle}
        callToActionButton={content.fields.callToActionButton?.fields}
      />
      {content.fields.sections?.map((section, index) => {
        const Component = mapContentTypeToComponent(
          section.sys.contentType.sys.id
        );
        return (
          <Component
            key={section.sys.id}
            positionOnPage={index}
            content={section}
          />
        );
      })}
      {content.fields.contactUsText && (
        <ContactUsModule
          backgroundColour={contactUsBackgroundColour}
          text={content.fields.contactUsText}
        />
      )}
    </>
  );
};

export default InfoPage;
