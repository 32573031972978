import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Entry, EntryFields, Asset } from 'contentful';
import { Link } from 'react-router-dom';
import {
  KText,
  KHeading,
  KButtonShapedLink,
  KIconLink,
} from '@xenzonegroup/kompass';

import { CardArticlesType, CardArticles } from '../components/CardArticles';
import { ArticleType } from '../pages/Article';
import ContactUsModule from '../components/ContactUsModule';
import { DescriptionListItemType } from '../components/DescriptionList';
import { NavigationLinkType } from '../components/NavigationMenu/NavigationLink';
import { FeaturedCaseStudy } from '../components/FeaturedCaseStudy';
import { VideoLink, VideoLinkType } from '../components/VideoLink';

import styles from './landing-page.scss';

const CaseStudies: React.FC<{ content: Entry<LandingPageType> }> = ({
  content,
}) => (
  <section>
    <div>
      {content.fields.featuredCaseStudyArticle && (
        <FeaturedCaseStudy content={content.fields.featuredCaseStudyArticle} />
      )}
      {content.fields.caseStudiesArticles && (
        <CardArticles
          content={content.fields.caseStudiesArticles}
          partOfAnotherSection={true}
        />
      )}
    </div>
  </section>
);

const UnstyledHeader: React.FC<{
  content: Entry<LandingPageType>;
  className?: string;
}> = ({ content, className }) => (
  <section className={`${className} ${styles.hero}`}>
    <div>
      <KHeading level={1} textStyle="heading_XL">
        {content.fields.title}
      </KHeading>
      <KText textStyle="large_text">{content.fields.strapline}</KText>
      {content.fields.videoLink && (
        <VideoLink
          content={content.fields.videoLink}
          playButton="play-big-alt-background"
        />
      )}
    </div>
  </section>
);

const Header = styled(UnstyledHeader)`
  ${props => css`
    background-image: url('${props.content.fields.heroIllustration.fields.file.url}');
  `}
`;

const Products: React.FC<{
  content: Entry<LandingPageType>;
  className?: string;
}> = ({ content, className }) => (
  <section className={`${className} ${styles.products}`}>
    <div>
      <KHeading level={2} colour="primary_inverted" textStyle="heading_S">
        Our products
      </KHeading>
      <KText textStyle="large_text" colour="primary_inverted">
        {content.fields.ourProductStrapline}
      </KText>
      <div className={styles.productList}>
        {content.fields.ourProducts &&
          content.fields.ourProducts.map(product => (
            <div key={product.sys.id}>
              <KHeading
                level={3}
                colour="primary_inverted"
                textStyle="heading_M"
              >
                {product.fields.title}
              </KHeading>
              <KText colour="primary_inverted">
                {product.fields.description}
              </KText>
              {product.fields.link?.fields.url && (
                <KButtonShapedLink
                  styleVariation="pop"
                  underline
                  to={product.fields.link?.fields.url}
                  renderReactRouterLinkInstead={Link}
                >
                  {product.fields.link?.fields.linkText}
                </KButtonShapedLink>
              )}
            </div>
          ))}
      </div>
    </div>
  </section>
);

const UnstyledPurpose: React.FC<{
  content: Entry<LandingPageType>;
  className?: string;
}> = ({ content, className }) => (
  <section className={`${className} ${styles.purpose}`}>
    <div>
      <KHeading level={2} textStyle="heading_L" colour="primary_inverted">
        {content.fields.ourPurposeStrapline}
      </KHeading>
      {content.fields.ourPurposeLink && (
        <KButtonShapedLink
          styleVariation="pop"
          underline
          to={content.fields.ourPurposeLink.fields.url}
          renderReactRouterLinkInstead={Link}
        >
          {content.fields.ourPurposeLink.fields.linkText}
        </KButtonShapedLink>
      )}
    </div>
  </section>
);

const Purpose = styled(UnstyledPurpose)`
  background-color: ${props =>
    props.theme.colours.text_friendly_background_colours.coloured_background_2};
`;
interface LandingPageType {
  title: EntryFields.Text;
  strapline: EntryFields.Text;
  videoLink?: Entry<VideoLinkType>;
  heroIllustration: Asset;
  featuredCaseStudyArticle?: Entry<ArticleType>;
  caseStudiesArticles?: Entry<CardArticlesType>;
  news?: Entry<CardArticlesType>;
  contactUsText?: EntryFields.Text;
  ourProductStrapline?: EntryFields.Text;
  ourProducts?: Entry<DescriptionListItemType>[];
  ourPurposeStrapline?: EntryFields.Text;
  ourPurposeLink?: Entry<NavigationLinkType>;
}

const LandingPage: React.FC<{ content: Entry<LandingPageType> }> = ({
  content,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Header content={content} />
      <Products content={content} />
      <div className={styles.textureIllustration} />
      <Purpose content={content} />
      {content.fields.featuredCaseStudyArticle &&
        content.fields.caseStudiesArticles && <CaseStudies content={content} />}
      {content.fields.news && <CardArticles content={content.fields.news} />}
      {content.fields.contactUsText && (
        <ContactUsModule
          text={content.fields.contactUsText}
          backgroundColour={
            theme.colours.text_friendly_background_colours.page_background
          }
        />
      )}
    </>
  );
};

export { LandingPage };
