import React from 'react';
import { KText, KLink } from '@xenzonegroup/kompass';
import styles from './GoToUsSiteBanner.styles.scss';
import { isServerSide } from '../../utils';

const GoToUsSiteBanner: React.FC<{}> = () => {
  if (isServerSide()) return null; /* 1 */

  return (
    <div className={styles.GoToUsSiteBanner}>
      <div className={styles.contentContainer}>
        <KText bold textStyle="heading_S">
          It looks like you&apos;re in the US. Do you want to go to the{' '}
          <KLink
            href="https://gov.kooth.com/us"
            colour="primary_text_colour"
            useVisitedStyles={false}
            textStyle="heading_S"
          >
            gov.kooth.com/us website
          </KLink>
          ?
        </KText>
      </div>
    </div>
  );
};

export { GoToUsSiteBanner };

/**
 * Note 1
 *
 * Rendering GoToUsSiteBanner only client side has a downside:
 * It will not appear in the HTML generated server side, and hence it'll only appear after the JS bundle has been executed by the client.
 * Concretely, that means that the banner will appear with a sub-1 second delay on the page.
 *
 * But that's a trade-off worth making in this case.
 * Because rendering that banner both server-side and client side would require adding quite a bit more complexity, which is not worth it in this case.
 */
